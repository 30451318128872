import React, { Component } from "react";
import { Modal, Col, Row, Dropdown, Button } from "react-bootstrap";
import socket from "../../../../Socket";
import { __, decode, encode, forceSatoshiFormat, isMobile, removeTrailingZeros, sendNotfication, Event } from "../../../../Helper";
import C from "../../../../Constant";
import 'react-international-phone/style.css';
import coins from "../../../coins";
import storage from "../../../../Storage";
const C_ = React.createElement;

class CryptoProvider extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            amount: 30,
            providerLists: [],
            depositamount: 30,
            currentCoin: "USDT",
            wallet_s : {
                'BTC': "bc1qk0kqat6jvluuzz9n5z046lex5gxdarxv5fmgsq",
                'ETH': "0x11456Bf81136F28F206381aD3DF49e20953b2087",
                'USDT': "0x11456Bf81136F28F206381aD3DF49e20953b2087",
                'LTC': "ltc1qx8z92jy3hjw4h2vf2r8cs7yhf4w5lzclptlafg",
                'BCH': "qzu35px9pl6q67m4l03ysuu0tha8xzf54c2m6nhcg2",
                'DOGE': "D724LqMKJyKteeu5jzAnaYiSkHKAED5P5e",
            },
            networkList: {
                "USDT": 'ETHEREUM',
                "BTC": 'BITCOIN',
                "ETH": 'ETHEREUM',
                "LTC": 'LITECOIN',
            },
            networkListGuarda: {
                "BTC": 'BTC',
                "ETH": 'ETH',
                "USDT": 'ETH',
                "LTC": 'LTC',
                "BCH": 'BCH',
                "DOGE": 'DOGE',
            },
            credits: {},
            list: [],
            selectedProvider: '',
            remoteIP: null,
            kado_pay: false,
            guarda_pay: false,
        };
        this.purchase = this.purchase.bind(this);
        this.onChange = this.onChange.bind(this);
        this.closeKado = this.closeKado.bind(this);
    }

    componentDidMount() {
        fetch("https://geolocation-db.com/json/")
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                this.setState({ remoteIP: res.IPv4 });
            })
            .catch(err => console.log(err))
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({ coin: "usdt" }));
        socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off(C.CREATE_PAYMENT);
        socket.off(C.BUY_CREDIT);
        socket.off(C.NOTIFY_PAYMENT);
        socket.off(C.CREDIT);
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let { credit } = data;
            this.setState({ credits: credit });
            this.setUpCoins();
        }
    };

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({ height: 685, margin: "mt-1" });
        }
        coins.forEach((item, i) => {
            if (item.preffix === "NC") return;

            let credit = forceSatoshiFormat(
                this.state.credits[__.toLower(item.preffix)]
            );

            let list = (
                <Dropdown.Item
                    key={__.toString(i)}
                    as={"button"}
                    className={"animated fadeIn"}
                    onClick={(e) => this.loadAddress(item.active, item.preffix)}
                >
                    <span>
                        <img
                            src={"/assets/images/" + item.image}
                            className={"img-fluid mini-coin mr-1"}
                            alt="Coin"
                        />{" "}
                        {item.preffix}
                    </span>
                </Dropdown.Item>
            );

            this.setState((state) => ({ list: [list, ...state.list] }));
        });
    };

    loadAddress(active, coin) {
        if (this._isMounted) {
            this.setState({
                currentCoin: coin,
                depositAddress: this.props.t("please_wait"),
            });
            if (active) socket.emit(C.GET_ADDRESS, encode({ coin: coin }));
            else {
                this.setState({
                    depositAddress: `${coin} ${this.props.t("not_supported_yet")}`,
                });
            }
        }
    }

    purchase(provider_name) {
        // event.preventDefault();
        switch (provider_name) {
            case "kado":
                // this.setState({ kado_pay: true });
                window.open(`https://app.kado.money/?apiKey=a00348d4-9051-4a78-b6f5-ed1efc3a1abd&onPayCurrency=USD&onRevCurrency=${this.state.currentCoin}&product=BUY&onPayAmount=${this.state.amount}&onToAddress=${this.state.wallet_s[this.state.currentCoin]}&network=${this.state.networkList[this.state.currentCoin]}&cryptoList=${this.state.currentCoin}&fiatList=USD&productList=BUY&fiatMethodList=card&userRef=${storage.getKey('uid')}`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=480,height=620")
                break;
            case "guarda":
                this.setState({ guarda_pay: true });
                // window.open(`https://guardarian.com/calculator/v1?partner_api_token=2bb276f8-cf93-4a72-899b-3f1ddbb34ace&theme=blue&type=narrow&default_side=buy_crypto&side_toggle_disabled=true&crypto_currencies_list=%5B%7B%22ticker%22%3A%22${this.state.currentCoin}%22%2C%22network%22%3A%22${this.state.networkListGuarda[this.state.currentCoin]}%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22EUR%22%2C%22network%22%3A%22EUR%22%7D%2C%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%2C%7B%22ticker%22%3A%22GBP%22%2C%22network%22%3A%22GBP%22%7D%5D&payout_address=${this.state.wallet_s[this.state.currentCoin]}&skip_choose_payout_address=true&redirects_successful=https://betsmixer.com`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=480,height=620")
                break;
            default:
            // Code to be executed if none of the cases match
        }

    }

    closeKado(e) {
        this.setState({ kado_pay: false });
    }

    closeGuarda(e) {
        this.setState({ guarda_pay: false });
    }

    onChange(e) {
        let inputValue = _.toNumber(e.target.value);
        if (!/^[0-9\b]+$/.test(inputValue)) {
            return;
        }
        this.setState({ amount: _.toNumber(e.target.value) });
        this.setState({ depositamount: inputValue })
    }

    increaseDepositAmount = () => {
        this.setState({ amount: _.toNumber(this.state.depositamount + 1) });
        this.setState({ depositamount: this.state.depositamount + 1 })
    }

    decreaseDepositAmount = () => {
        if (this.state.depositamount < 1) return;
        this.setState({ amount: _.toNumber(this.state.depositamount - 1) });
        this.setState({ depositamount: this.state.depositamount - 1 })
    }

    getCoinName = (coin) => {
        let find = __.find(coins, function (o) {
            return o.preffix === __.upperCase(coin);
        });
        return find.name;
    };

    render() {
        const { t } = this.props;
        const { providerLists, selectedProvider, amount } = this.state;
        const networksList = {
            // "USDT": 'Ethereum, Bitcoin, Solana, Avalanche, Polygon,Stellar',
            // "BITCOIN": 'Bitcoin,Solana, Avalanche, Polygon, CosmosHub, Stellar',
            // "ETH": 'Ethereum, Avalanche, Polygon, Cosmos Hub, Stellar, Arbitrum, Optimism',
            // "LTC": 'Ethereum, Solana, Avalanche, Polygon, Stellar',
            // "BCH": 'Ethereum, Solana, Avalanche, Polygon, Cosmos Hub, Stellar',
            // "DOGE": 'Ethereum, Solana, Avalanche, Polygon, Stellar',
            "USDT": 'Ethereum',
            "BITCOIN": 'Bitcoin',
            "ETH": 'Ethereum',
            "LTC": 'Polygon',
            "BCH": 'Solana',
            "DOGE": 'Solana',
        }
        
        return (
            <>
                <Row className="m-0">

                    <div className="m-4 provider-top">
                        <div className="provider-container">
                            <div className="full-width amount-list">
                                <div className="media pl-0 col-5 col coin-balance">
                                    <div className="media">
                                        <img
                                            src={
                                                "/assets/images/" +
                                                __.toLower(this.state.currentCoin) +
                                                ".png"
                                            }
                                            className="mr-3 thumb-sm align-self-center rounded-circle"
                                            alt="Coin"
                                        />
                                        <div className="media-body align-self-center">
                                            <div className="coin-bal">
                                                <h4 className="m-0 text-white">
                                                    {removeTrailingZeros(
                                                        forceSatoshiFormat(
                                                            this.state.credits[
                                                            __.toLower(this.state.currentCoin)
                                                            ]
                                                        )
                                                    ) == 'NaN' ? '' : removeTrailingZeros(
                                                        forceSatoshiFormat(
                                                            this.state.credits[
                                                            __.toLower(this.state.currentCoin)
                                                            ]
                                                        )
                                                    )}
                                                </h4>
                                                <p className="text-muted mb-0">
                                                    {this.getCoinName(this.state.currentCoin)}
                                                    <span className="text-muted font-12">
                                                        {" "}
                                                        ({this.state.currentCoin})
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="media pl-0 col-3 col cointype">
                                    <Dropdown bottom="true">
                                        <Dropdown.Toggle
                                            split
                                            align="end"
                                            variant="mt-2 text-white"
                                            id="dropdown-split-coins"
                                        >
                                            <span style={{ fontSize: 17 }}>
                                                <img
                                                    src={
                                                        "/assets/images/" +
                                                        __.toLower(this.state.currentCoin) +
                                                        ".png"
                                                    }
                                                    className={"img-fluid mini-coin-12 mr-2"}
                                                    alt="Coin"
                                                />
                                                {this.state.currentCoin}
                                            </span>
                                            <span className="ml-1 nav-user-name">
                                                <span className="caret"></span>
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            className={"droper clist-coins user-dropdown-detail"}
                                        >
                                            {this.state.list}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="amount-input-container col-4 text-right col">
                                    <div className="amount-arrow d-flex flex-column">
                                        <svg className="amount-arrow-up" onClick={this.increaseDepositAmount} width="17px" height="17px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                            </g>
                                        </svg>
                                        <svg className="amount-arrow-down" onClick={this.decreaseDepositAmount} width="17px" height="17px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="matrix(1, 0, 0, -1, 0, 0)">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <input className="amount-input" type="number" placeholder="100.00" value={this.state.depositamount} onChange={(e) => this.onChange(e)}></input>
                                    <div className="amount-label">USD</div>
                                </div>
                            </div>
                            <div className="crypto-button my-4 d-flex justify-content-center align-items-center gap-3">
                                <button 
                                    type="submit" 
                                    className="guarda-button"
                                    onClick={() => this.purchase("guarda")}
                                >
                                    <i className="mdi mdi-credit-card-multiple cardicon"></i> Buy with Guarda
                                </button>
                                <span>OR</span>
                                <button
                                    type="submit"
                                    onClick={() => this.purchase("kado")}
                                    disabled={this.state.currentCoin === 'BCH' || this.state.currentCoin === 'DOGE'}
                                    className="purchase-button"
                                >
                                    <i className="mdi mdi-credit-card-multiple cardicon"></i> Buy with Kado
                                </button>
                            </div>
                            <Modal
                                size="lg"
                                scrollable={true}
                                backdrop="static"
                                centered={true}
                                show={this.state.guarda_pay}
                                onHide={()=>this.closeGuarda()}
                                className={"animated"}
                            >
                                <Modal.Header className="Header">
                                    Pay with Guardarian
                                    <button type="button" className="close p-2" onClick={()=>this.closeGuarda()}>
                                        <i className={'mdi mdi-close'} />
                                    </button>
                                </Modal.Header>
                                <Modal.Body style={{ height: '500px', maxHeight: '500px' }}>
                                    <iframe
                                        height="450px"
                                        width="100%"
                                        title="Guardarian Widget"
                                        src={`https://guardarian.com/calculator/v1?partner_api_token=2bb276f8-cf93-4a72-899b-3f1ddbb34ace&theme=blue&default_fiat_amount=${this.state.amount}&external_partner_link_id=${storage.getKey('uid')}&type=narrow&default_side=buy_crypto&side_toggle_disabled=true&crypto_currencies_list=%5B%7B%22ticker%22%3A%22${this.state.currentCoin}%22%2C%22network%22%3A%22${this.state.networkListGuarda[this.state.currentCoin]}%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%5D&payout_address=${this.state.wallet_s[this.state.currentCoin]}&skip_choose_payout_address=true&redirects_successful=https://betsmixer.com`}
                                    />
                                </Modal.Body>
                            </Modal>
                            <Modal
                                size="lg"
                                scrollable={true}
                                backdrop="static"
                                centered={true}
                                show={this.state.kado_pay}
                                onHide={this.closeKado}
                                className={"animated"}
                            >
                                <Modal.Header className="Header">
                                    Pay with KADO
                                    <button type="button" className="close p-2" onClick={this.closeKado}>
                                        <i className={'mdi mdi-close'} />
                                    </button>
                                </Modal.Header>
                                <Modal.Body style={{ height: '660px', maxHeight: '660px' }}>
                                    {/* {console.log(`https://sandbox--kado.netlify.app/?apiKey=a00348d4-9051-4a78-b6f5-ed1efc3a1abd&onPayCurrency=USD&onRevCurrency=${this.state.currentCoin}&product=BUY&onPayAmount=${this.state.amount}&onToAddress=${wallet_s[this.state.currentCoin]}&cryptoList=${this.state.currentCoin}&fiatList=USD&productList=BUY&fiatMethodList=card&userRef=${storage.getKey('uid')}`)}
                                    <iframe src={`https://sandbox--kado.netlify.app/?apiKey=a00348d4-9051-4a78-b6f5-ed1efc3a1abd&onPayCurrency=USD&onRevCurrency=${this.state.currentCoin}&product=BUY&onPayAmount=${this.state.amount}&onToAddress=${wallet_s[this.state.currentCoin]}&cryptoList=${this.state.currentCoin}&fiatList=USD&productList=BUY&fiatMethodList=card&userRef=${storage.getKey('uid')}`} width="480" height="620" style={{ border: 0 }}></iframe> */}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </Row>
            </>
        );
    }
}

export default CryptoProvider;
