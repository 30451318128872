import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import { isMobile, wait, Event, encode } from "../../../Helper";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

import "../../../Static/css/goal.css";

class Main extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }
    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { engine, col, padding, ovh } = this.state;
    const { t, gid } = this.props;
    const help = (
      <p className="font-light text-white font-15">
        Goal is simple game, place your bet and choose your risk option. When
        you achieve to the goal you can win multiplier amount listed at the
        bottom of each column. You can cashout whenever you want, but be careful
        of the mines! When you drop the ball on a mine you lose the bet.
      </p>
    );
    return (
      <>
        <Helmet>
          <title>Goal - Crypto Casino Games</title>
          <meta
            name="description"
            content="Crypto Goal Game is simple, place your bet amount and choose your risk variance. As you progress to the goal you will the multiplier amount listed at the bottom of each column."
          />
          <meta
            name="og:title"
            content="Play Online Goal - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Goal - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/goal" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">Crypto Goal Game Description: {help}</div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* MENUS */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            goal
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"goal"} />%
                          </span>
                        </p>
                        <BankRoll game={"goal"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Goal" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body
                    className={"p-1 py-2 goal-face roll-panel-goal"}
                    id="roll-panel"
                  >
                    <Row>
                      <Col sm={12} md={10} className={"m-auto"}>
                        <Game engine={engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BETS */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Main;
