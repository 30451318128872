import React, { Component, createRef } from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import {Event, wait, decode, encode, sendNotfication, RECAPTCHA} from "../../../Helper";
import C from "../../../Constant";
class ReportBugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recaptcha: false,
            title: "",
            content: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.recaptchaChange = this.recaptchaChange.bind(this);

        this.recaptchaRef = createRef();
    }
    componentDidMount() {
        socket.on(C.REPORT_BUG, data => {
            if(decode(data) === true) {
                sendNotfication(this.props.t('successfully_reported_bug'), 'success','bottom-left');
            } else {
                sendNotfication(this.props.t('unfortunately_there_is_some_issue_on_your_server'), 'success','bottom-left');
            }
        });
    }
    componentWillUnmount() {
        socket.off(C.REPORT_BUG);
    }
    handleSubmit = () => {
        socket.emit(C.REPORT_BUG, encode({title: this.state.title, content: this.state.content}));
    }
    recaptchaChange = (value) => {
        this.setState({ recaptcha: value });
    };
    render() {
        const { t } = this.props;
        return (
            <>
              <Helmet>
                <title>Report bugs - Crypto Casino Games</title>
                <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <link rel="canonical" href="/report-bug" />
              </Helmet>
                <Row>
    
                    <Row>
                        <Col>
                            <label htmlFor="title">{t('title')}:</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="title"
                                    autoComplete={'off'}
                                    className="form-control"
                                    placeholder={t('please_type_title')}
                                    defaultValue={this.state.title}
                                    required={true}
                                    onChange={e => this.setState({title: e.target.value})}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <label htmlFor="content">{t('description')}:</label>
                            <div className="input-group">
                                <textarea
                                    type="text"
                                    id="content"
                                    autoComplete={'off'}
                                    className="form-control"
                                    placeholder={t('please_describe_issue_or_detected')}
                                    required={true}
                                    rows={4}
                                    defaultValue={this.state.content}
                                    onChange={e => this.setState({content: e.target.value})}
                                >
                                </textarea>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3 reportbugbutton'>
                        <ReCAPTCHA ref={this.recaptchaRef} sitekey={RECAPTCHA} onChange={this.recaptchaChange}/>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col>
                        <button
                            type="button"
                            className="btn btn- mt-3 btn-purple px-4 no-shadow w-100 reportbutton"
                            disabled={!(this.state.title != "" && this.state.content != "" && this.state.recaptcha)}
                            onClick={this.handleSubmit}
                            >
                            {t('submit')}
                            </button> 
                         </Col>
                    </Row>
                    <Row className='d-flex justify-content-center depositinforow'>
                        <Col>
                            <div className="depositinfo"><div className="alert bg-soft-info nhv mb-0 infodeposit reportinfo"><p className="text-white font-13 mb-0"><span className="text-yellow">{t('note')} 
                            </span> {t('you_can_report_bug_or_technical_issue_on_site_etc')} <a href="mailto:info@betsmixer.com" className="text-yellow">info@betsmixer.com</a></p></div><span className="encryption encryption2"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-lock fa-w-14"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>256-bit {t('encryption')}</span></div>
                        </Col>
                    </Row>
                </Row>
            </>
        );
    }
}

export default withRouter(ReportBugs);
