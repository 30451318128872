import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import { isMobile, wait, Event, encode } from "../../../Helper";
import Loading from "../Loading";
import "../../../Static/css/hash_dice.css";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      margin: "mt-3",
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);

    wait(300).then(() => {
      this._Mounted = false;
    });

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", margin: "mt-1", ovh: "ovh" });
    }
  }
  render() {
    let { col, padding, margin, ovh } = this.state;
    const { t, gid } = this.props;
    const help = (
      <>
        <p className={"font-light text-white font-15"}>
          Hash Dice is a game of betting below or under the number 50000 by the
          payout. If you choose “low” that means the number must come in under
          49999 and higher than your payout. If you choose “High” that means
          number must come in 50000 or over than your payout. First you will
          choose your bet amount and the payout multiplier and then your
          variance (low/high). The payout is the x amount times your bet amount
          that you will win.
        </p>
        <p className={"font-light text-white font-15"}>
          Example: If you bet .10 on low variance with a payout of 5 and the
          number comes out as 35987 you win 5x your bet amount. If the number
          rolled is 50000 or over you lose the bet.
        </p>
        <h4 className="text-white text-success">Provaily Fair ?</h4>
        <p className={"font-light text-white font-15 l26"}>
          To generate a roll number between 0 and 99,999: <br />
          First we calculate the hash value of the combination with HMAC_SHA512.
          This gives us a 64-character hexadecimal string: hash = HMAC_SHA512
          (clientSeed:nonce, serverSeed).
          <br />
          We then take the first 5 characters of that hash and convert them to a
          decimal number ranging from 0 to 1,048,575 (16 ^ 5 - 1). <br />
          If it is less than 1 million, we divide it by 100,000 and use it as
          your roll outcome. Otherwise, we repeat using the next five
          characters. We are able to repeat the process up to 25 times.
          <br />
          In the very rare case ((48,576 / 1,000,000) ^ 25) that none of the 25
          trials are lower than 1 million when converted to decimal, we use the
          remaining 3 characters and convert them to your roll number.
          <br />
        </p>
      </>
    );

    return (
      <>
        <Helmet>
          <title>Hash Dice - Crypto Casino Games</title>
          <meta
            name="description"
            content={
              "Hash Dice is a game of betting below or under the number 50000 by the payout. If you choose “low” that means the number must come in under 49999 and higher than your payout. If you choose “High” that means  number must come in 50000 or over than your payout."
            }
          />
          <meta
            name="og:title"
            content="Play Online Hash Dice - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Hash Dice - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/hash-dice" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">
                Crypto Hash Dice Game Description: {help}
              </div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            hash Dice
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"hashdice"} />%
                          </span>
                        </p>
                        <BankRoll game={"hash_dice"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Hash Dice" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body
                    className={
                      "bg-dice-options hash_diceBG p-1 roll-panel-goal"
                    }
                    id="roll-panel"
                  >
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <Game engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
