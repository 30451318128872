import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { encode, decode, __, Event, isMobile, isTablet } from "../../Helper";

class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          name: 'amatic',
          image: "alc"
        },
        {
          name: 'betgames',
          image: "avatar"
        },
        {
          name: 'big time Gaming',
          image: "bigtime"
        },
        {
          name: 'boominggames',
          image: "boom"
        },
        {
          name: 'blueprint',
          image: "bull"
        },
        {
          name: 'Endorphina',
          image: "end"
        },
        {
          name: 'Evolution',
          image: "evo"
        },
        {
          name: 'Habanero',
          image: "hab"
        },
        {
          name: 'kalamba',
          image: "hacksaw"
        },
        {
          name: 'Netent',
          image: "netent"
        },
        {
          name: 'novomatic',
          image: "nolimit"
        },
        {
          name: 'playson',
          image: "play"
        },
        {
          name: 'platipus',
          image: "pop"
        },
        {
          name: 'pragmaticplay',
          image: "pragmatic"
        },
        {
          name: 'onetouch',
          image: "push"
        },
        {
          name: 'Redrake',
          image: "red-tiger"
        },
        {
          name: 'retrogaming',
          image: "rela"
        },
        {
          name: 'threeoaks',
          image: "tada"
        },
        {
          name: 'Thunderkick',
          image: "thu"
        },
        {
          name: 'turbogames',
          image: "turbo"
        },
      ],
    };
  }

  render() {
    const providers = this.state.items.map((item, i) => (
      <>
        <Col md="2" sm="6" className="mb-2">
          <Link to={"/provider/" + item.name}>
            <img
              className="img-fluid"
              src={`/assets/images/provider/new/${item.image}.png`}
            />
          </Link>
        </Col>
      </>
    ));
    return (
      <>
          <h2 className="text-white font-15">Providers</h2>
        <Row>{providers}</Row>
      </>
    );
  }
}

export default Providers;
