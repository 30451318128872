import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PerfectScrollbar from "perfect-scrollbar";
import { Event, sendNotfication, isLogged } from "../../../../Helper";
import { Col, Row, Card, Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import SportsSubmenu from "./Sports-submenu";
import Translation from "../Translation";
import FreeCredit from "../../../Components/User/FreeCredit";

class Menu extends React.Component {
  constructor(props) {
    const path = window.location.pathname;
    const side_menus = ["live", "slots"];
    const firstPath = path.split("/")[1];
    super(props);
    this.state = {
      show: false,
      home: false,
      activeMenu: side_menus.includes(firstPath) ? firstPath : "",
      loaded: false,
      loading: false,
      isLogged: storage.getKey("logged") !== null ? true : false,
    };
  }
  componentDidMount() {
    Event.on("show_support", () => {
      this.support();
    });
    Event.on("active_games", () => {
      this.active("activeGames");
    });
    Event.on("active_link", (link) => {
      this.activeLink(link);
    });
    Event.on("show_games", () => {
      this.setState({ show: true });
    });
    Event.on("hide_games", () => {
      this.setState({ home: true });
    });
    Event.on("show_min_games", () => {
      // this.setState( { home: false });
    });
    const ps = new PerfectScrollbar("#lss", {
      wheelSpeed: 1,
      suppressScrollX: true,
      wheelPropagation: true,
      minScrollbarLength: 2,
    });
    ps.update();
  }

  active = (link) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setState({
      activeMenu: link,
    });
    Event.emit("clear_search");
  };

  openDepositModal = (e) => {
    if (this.state.isLogged) {
      Event.emit("deposit_open");
    } else {
      Event.emit("login_form");
    }
  };

  openOffer = (e) => {
    Event.emit("clear_search");
    return this.state.isLogged
      ? Event.emit("offerwall")
      : Event.emit("register_form")
  }

  render() {
    const { isLogged, activeMenu } = this.state;
    const { t, type } = this.props;
    return (
      <>
        {type === "min" && <ReactTooltip />}
        <div
          id="lss"
          className="menu-body m-0"
          onMouseLeave={() => this.setState({ show: false })}
        >
          <div className="menu-pane d-flex flex-column">
            <p className="in-house-game-title p-2 m-0">{t("in_house_game")}</p>
            <Row xs={4} md={4} className="in-house-game-wrapper w-100 p-2 m-0">
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/crash">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/1.svg"
                      className="img-fluid w-100 side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>

              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/roulette">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/2.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/diamond">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/3.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/plinko">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/4.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/3-card-monte">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/5.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/keno">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/6.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/videopoker">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/7.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
              <Col className="col-3 p-1">
                <Link onClick={() => this.active("")} to="/classic-dice">
                  <div className="position-relative">
                    <img
                      src="/assets/images/sidebar/in-house/8.svg"
                      className="img-fluid w-100  side-inhome"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
            </Row>

            <div className="menu-link-wrapper p-2 d-flex flex-column gap-2">

             <Link to={"#"} className="b-subtitle" onClick={() => this.openOffer()}>
                <button
                  className={`btn menu-link-btn bonusbtn`}
                  onClick={() => this.active("")}
                >
                  <div className="menu-link-btn-img-wrapper">
                    <span>GET <span className="hightlight-bonus"> 15 USD</span></span>
                  </div>
                </button>
              </Link>

              {/*
              <Link to={"/sports"} onClick={() => this.active("")} >
                <button
                  className={`btn sports-btn-wrapper ${
                    activeMenu == "sports" ? "active" : ""
                  }`}
                >
              <div className="menu-link-btn-img-wrapper">
                  <img
                    className="img-fluid"
                    src="/assets/images/sidebar/recent.svg"
                  />
                  <span>{t("sports")}</span>
                  <SportsSubmenu t={t} />
                  
                  </div>
                </button>
              </Link>
              */}

              <Link to={"/live"}>
                <button
                  className={`btn menu-link-btn ${
                    activeMenu == "live" ? "active" : ""
                  }`}
                  onClick={() => this.active("live")}
                >
                  <div className="menu-link-btn-img-wrapper">
                    <img
                      className="img-fluid img-default"
                      src="/assets/images/sidebar/games-info/live_casino.svg"
                    />
                    <img
                      className="img-fluid img-top"
                      src="/assets/images/sidebar/games-info/live_casino_active.svg"
                    />
                    <span>{t("live_casino")}</span>
                  </div>
                </button>
              </Link>
              <Link to={"/slots"}>
                {/* <button className={`btn menu-link-btn ${activeVip ? 'active' : ''}`} onClick={() => this.active('activeVip')}> */}
                <button
                  className={`btn menu-link-btn ${
                    activeMenu == "slots" ? "active" : ""
                  }`}
                  onClick={() => this.active("slots")}
                >
                  <div className="menu-link-btn-img-wrapper">
                    <img
                      className="img-fluid img-default"
                      src="/assets/images/sidebar/games-info/slots.svg"
                    />
                    <img
                      className="img-fluid img-top"
                      src="/assets/images/sidebar/games-info/slots_active.svg"
                    />
                    <span>{t("slots")}</span>
                  </div>
                </button>
              </Link>

               <Link to={"/"}>
                {/* <button className={`btn menu-link-btn ${activeVip ? 'active' : ''}`} onClick={() => this.active('activeVip')}> */}
                <button
                  className={`btn menu-link-btn`}
                   onClick={() => this.openOffer()}
                >
                  <div className="menu-link-btn-img-wrapper">
                    <img
                      className="img-fluid img-default unionimg"
                      src="/assets/images/union.png"
                    />
                    <img
                      className="img-fluid img-top unionimg"
                      src="/assets/images/union.png"
                    />
                    <span>Offerwall</span>
                  </div>
                </button>
              </Link>

            </div>

            <div className="menu-text-wrapper p-2 d-flex flex-column gap-2">
              {/*
                <Link to={"#"} className="b-title">
                {t("other")}
              </Link>
              */}
              <Link
                to={"/affiliate"}
                className="b-subtitle"
                onClick={() => this.active("")}
              >
                <i class="mdi mdi-account-multiple-plus font-15"></i>

                {t("affiliate")}
                <div className="intro-banner-vdo-play-btn-promo-2 pinkBg-promo-2">
                  <span className="ripple pinkBg-promo-2"></span>
                  <span className="ripple pinkBg-promo-2"></span>
                  <span className="ripple pinkBg-promo-2"></span>
                </div>
              </Link>
              <Link
                onClick={() => this.active("")}
                to={"/daily-contest"}
                className="b-subtitle dailycontest"
              >
                <i class="mdi mdi-trophy font-15"></i>
                <span className="dailycontesttitle">{t("top_winners")}</span>
                <div className="intro-banner-vdo-play-btn pinkBg hidden">
                  <span className="ripple pinkBg"></span>
                  <span className="ripple pinkBg"></span>
                  <span className="ripple pinkBg"></span>
                </div>
              </Link>

              <Link
                to={"/promotion"}
                className="b-subtitle dailycontest"
                onClick={() => this.active("")}
              >
                <i class="mdi mdi-emoticon-cool font-15"></i>
                <span className="dailycontesttitle">{t("promotions")}</span>
                <div className="intro-banner-vdo-play-btn-promo pinkBg-promo hidden">
                  <span className="ripple pinkBg-promo"></span>
                  <span className="ripple pinkBg-promo"></span>
                  <span className="ripple pinkBg-promo"></span>
                </div>
              </Link>

              {/* <Link
                to={"/giveaway"}
                className="b-subtitle"
                onClick={() => this.active("")}
              >
                <span>{t("giveaway")}</span>
                <div className="intro-banner-vdo-play-btn-promo-2 pinkBg-promo-2">
                  <span className="ripple pinkBg-promo-2"></span>
                  <span className="ripple pinkBg-promo-2"></span>
                  <span className="ripple pinkBg-promo-2"></span>
                </div>
              </Link>
               */}

              
              <Link
                to={"/vip-levels"}
                className="b-subtitle"
                onClick={() => this.active("")}
              >
                <i class="mdi mdi-star-half font-15"></i>VIP {t("levels")}
              </Link>
               {/*
              <Link to={"#"} className="b-subtitle" onClick={() => this.openOffer()}>
                <i class="mdi mdi-coin font-15"></i>Offerwall
              </Link>
               */}

              <FreeCredit t={this.props.t} disableButton={true} />
            </div>

            {/*
            <div className="menu-footer-wrapper p-2 d-flex flex-column gap-2">
              <div className="d-flex align-items-center help-center-wrapper">
                <a href="https://forum.betsmixer.com/" target="_blank" className="b-subtitle" >
                  <span>{t("forum")} —</span>
                </a>
                <Link to={"/help-center"} className="b-subtitle helpcenterlink">
                  {t("help_center")}
                </Link>
              </div>
              */}

            <div className="menu-footer-wrapper p-2 d-flex flex-column gap-2">
              <div className="d-flex align-items-center help-center-wrapper">
                <Link onClick={() => this.active("")} to={"#"} className="b-subtitle my_custom_intercom">
                  <span>Support — </span>
                </Link>
                <Link to={"/help-center"} className="b-subtitle helpcenterlink">
                  {t("help_center")}
                </Link>
              </div>

              {/*
              <div className="d-flex align-items-center">
                <Link to={"#"} className="b-subtitle my_custom_intercom">
                  <span>{t("live_support")}</span>
                  <svg
                    className="iconchat"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#717A98"
                      d="M18.72 14.76c.35-.85.54-1.76.54-2.76c0-.72-.11-1.41-.3-2.05c-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.34a9.21 9.21 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.27c1.05 0 2.06-.23 2.97-.64c.57 1.09.83 1.63.81 1.63c-1.64.55-2.91.82-3.78.82c-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6a8.991 8.991 0 0 1 2.47 4.6H22v4.55h-.06L18.38 18l-5.3-.6v-1.67h4.83l.81-.97m-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33c-.63 0-1.14-.5-1.14-1.13c0-.63.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14c0 .63-.5 1.13-1.13 1.13c-.63 0-1.14-.5-1.14-1.13a1.14 1.14 0 0 1 1.14-1.14Z"
                    />
                  </svg>
                </Link>
              </div>
              */}

              <Link onClick={() => this.active("")} className="termslink" to={"/rules?tab=1"}>
                {t("terms_conditions")}
              </Link>
            </div>

            <div className="menu-social-wrapper socialiconsmenu p-2 justify-content-start mt-3 d-flex">
              <a href="https://twitter.com/betsmixer" target="_blank">
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid "
                    src="/assets/images/sidebar/social/twitter.svg"
                  />
                </button>
              </a>
              <a
                href="https://t.me/betsmixer"
                target="_blank"
                className="telegramsidebar"
              >
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid "
                    src="/assets/images/sidebar/social/telegram.png"
                  />
                </button>
              </a>
              <Link onClick={() => this.active("")} to={"#"} className="b-subtitle my_custom_intercom">
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid livesupporticon"
                    src="/assets/images/sidebar/social/livesupport.svg"
                  />
                </button>
              </Link>
              {/* <Translation /> */}
            </div>

            <div className="menu-social-wrapper socialiconsmenu socialiconsmenuinfo p-2 justify-content-start mt-3 d-flex">
              <a href="https://team.betsmixer.com/" target="_blank">
                <button
                  className={
                    "btn btn-xs menu-social-btn socialiconsmenuinfoleft"
                  }
                >
                  <i className="mdi mdi-account-group text-muted mr-2 text-drop"></i>
                  {t("team")}
                </button>
              </a>
              <a href="/rakeback" target="_blank" className="telegramsidebar">
                <button
                  className={
                    "btn btn-xs menu-social-btn socialiconsmenuinforight"
                  }
                >
                  <i className="mdi mdi-alpha-r-circle-outline text-muted mr-2 text-drop"></i>
                  {t("rewards")}
                </button>
              </a>
            </div>

            <div className="cryptosidebar">
              <button
                className="cryptosidebartitle"
                onClick={this.openDepositModal}
              >
                {t("buy_crypto")}
              </button>
              <div className="cryptosidebaricons">
                <img
                  className="img-fluid paymentmethods"
                  src="/assets/images/paymentmethods.png"
                />

                {/* <svg
                  height="26"
                  viewBox="0 0 79 26"
                  width="79"
                  xmlns="http://www.w3.org/2000/svg"
                  size="39"
                  class="visaicon"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#1767ee">
                      <path d="m34.2402205 24.7391863h-6.3714503l3.9851615-23.99780858h6.3711019z"></path>
                      <path d="m57.3372941 1.32805567c-1.25673-.48556168-3.2500076-1.02168149-5.7146896-1.02168149-6.2920118 0-10.7227951 3.26761632-10.7499714 7.93932363-.0522622 3.44677539 3.1723112 5.36119809 5.5840342 6.51046259 2.4650304 1.1743738 3.3029666 1.9408894 3.3029666 2.9876803-.0250859 1.6076808-1.991884 2.3487477-3.8262847 2.3487477-2.543772 0-3.9067683-.3820702-5.9780907-1.2765081l-.8389814-.3834274-.8915919 5.3869861c1.4943485.6630241 4.2475174 1.2513987 7.1059075 1.2771867 6.6853715 0 11.0377616-3.2170582 11.0893269-8.1955075.0254342-2.7318358-1.677266-4.8250783-5.3481579-6.5355719-2.2284572-1.09802768-3.5931956-1.83841593-3.5931956-2.96189232.0261311-1.02134217 1.1542963-2.06745443 3.6698467-2.06745443 2.0713224-.05123677 3.5931956.43364627 4.7460982.91886864l.576277.25482656z"></path>
                      <path
                        d="m65.8055011 16.2376155c.5247117-1.3786423 2.543772-6.71439168 2.543772-6.71439168-.0264795.05123676.5236665-1.404091.8382845-2.29751091l.4452734 2.06779375s1.2065583 5.74428624 1.4685658 6.94410884c-.9957678 0-4.0374237 0-5.2958957 0zm7.8647535-15.49623778h-4.9283187c-1.5197827 0-2.6744274.43364628-3.3301429 1.99110826l-9.4639747 22.00636102h6.6853715s1.1006405-2.9618923 1.3372137-3.5998071h8.1804168c.1829175.8425225.7602398 3.5998071.7602398 3.5998071h5.8993491z"
                        fill-rule="nonzero"
                      ></path>
                      <path d="m22.5470918.74137772-6.2397498 16.36420898-.6818465-3.3188531c-1.1535994-3.82952419-4.7715324-7.9902211-8.80895606-10.0590328l5.71538636 20.9860367h6.7376337l10.0148175-23.97235978z"></path>
                    </g>
                    <path
                      d="m10.513214.74137772h-10.25104234l-.10487267.48488305c7.99645412 1.99144757 13.29234981 6.79175578 15.46819651 12.56149073l-2.2284572-11.02913816c-.3668802-1.53269189-1.4943485-1.96667748-2.8838243-2.01723562z"
                      fill="#ec982d"
                    ></path>
                  </g>
                </svg>
                <svg
                  height="45"
                  viewBox="0 0 68 45"
                  width="68"
                  xmlns="http://www.w3.org/2000/svg"
                  size="27.5"
                  class="mastercardicon"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="m0 0h67.68576v44.149197h-67.68576z"
                      fill="#fff"
                      opacity="0"
                    ></path>
                    <path
                      d="m24.91788 7.691816h17.855152v28.760484h-17.855152z"
                      fill="#ff5f00"
                    ></path>
                    <path
                      d="m26.7569704 22.0745983c-.0044831-5.6113672 2.6074955-10.9131435 7.0833336-14.37770153-7.6037446-5.89330719-18.5230268-5.03489604-25.08406848 1.97196517-6.5610417 7.00686126-6.5610417 17.80969196 0 24.81655326 6.56104168 7.0068612 17.48032388 7.8652723 25.08406848 1.9719651-4.4772624-3.4656582-7.0894007-8.7696319-7.0833336-14.382782z"
                      fill="#eb001b"
                    ></path>
                    <g fill="#f79e1b">
                      <path
                        d="m62.1113048 34.1402643v-1.1951516h.2833333v-.2472727h-.6743333v.2472727h.2663333v1.1951516zm1.309 0v-1.4424243h-.204l-.238 1.0303031-.238-1.0303031h-.204v1.4424243h.1473333v-1.0921213l.221.9375758h.153l.221-.9375758v1.0921213z"
                        fill-rule="nonzero"
                      ></path>
                      <path d="m63.8478806 22.0745983c0 7.0025912-4.0545409 13.3903906-10.4412173 16.4504753s-13.9843887 2.2552166-19.5663593-2.0727737c4.4738851-3.4674994 7.0860604-8.7685708 7.0860604-14.3802418s-2.6121753-10.9127425-7.0860604-14.38024179c5.5819706-4.32799037 13.1796829-5.13285847 19.5663593-2.07277373 6.3866764 3.06008473 10.4412173 9.44788412 10.4412173 16.45047532z"></path>
                    </g>
                  </g>
                </svg>
                <svg
                  viewBox="0 0 512 210.2"
                  xmlns="http://www.w3.org/2000/svg"
                  size="40"
                  class="applepayicon"
                >
                  <path d="m93.6 27.1c-6 7.1-15.6 12.7-25.2 11.9-1.2-9.6 3.5-19.8 9-26.1 6-7.3 16.5-12.5 25-12.9 1 10-2.9 19.8-8.8 27.1m8.7 13.8c-13.9-.8-25.8 7.9-32.4 7.9-6.7 0-16.8-7.5-27.8-7.3-14.3.2-27.6 8.3-34.9 21.2-15 25.8-3.9 64 10.6 85 7.1 10.4 15.6 21.8 26.8 21.4 10.6-.4 14.8-6.9 27.6-6.9 12.9 0 16.6 6.9 27.8 6.7 11.6-.2 18.9-10.4 26-20.8 8.1-11.8 11.4-23.3 11.6-23.9-.2-.2-22.4-8.7-22.6-34.3-.2-21.4 17.5-31.6 18.3-32.2-10-14.8-25.6-16.4-31-16.8m80.3-29v155.9h24.2v-53.3h33.5c30.6 0 52.1-21 52.1-51.4s-21.1-51.2-51.3-51.2zm24.2 20.4h27.9c21 0 33 11.2 33 30.9s-12 31-33.1 31h-27.8zm129.8 136.7c15.2 0 29.3-7.7 35.7-19.9h.5v18.7h22.4v-77.6c0-22.5-18-37-45.7-37-25.7 0-44.7 14.7-45.4 34.9h21.8c1.8-9.6 10.7-15.9 22.9-15.9 14.8 0 23.1 6.9 23.1 19.6v8.6l-30.2 1.8c-28.1 1.7-43.3 13.2-43.3 33.2 0 20.2 15.7 33.6 38.2 33.6zm6.5-18.5c-12.9 0-21.1-6.2-21.1-15.7 0-9.8 7.9-15.5 23-16.4l26.9-1.7v8.8c0 14.6-12.4 25-28.8 25zm82 59.7c23.6 0 34.7-9 44.4-36.3l42.5-119.2h-24.6l-28.5 92.1h-.5l-28.5-92.1h-25.3l41 113.5-2.2 6.9c-3.7 11.7-9.7 16.2-20.4 16.2-1.9 0-5.6-.2-7.1-.4v18.7c1.4.4 7.4.6 9.2.6z"></path>
                </svg>
                <svg
                  fill="#fff"
                  viewBox="0 0 435.97 173.13"
                  xmlns="http://www.w3.org/2000/svg"
                  size="42.5"
                  class="googlepayicon"
                >
                  <g fill="#fff">
                    <path d="m206.2 84.58v50.75h-16.1v-125.33h42.7a38.61 38.61 0 0 1 27.65 10.85 34.88 34.88 0 0 1 11.55 26.45 34.72 34.72 0 0 1 -11.55 26.6q-11.2 10.68-27.65 10.67h-26.6zm0-59.15v43.75h27a21.28 21.28 0 0 0 15.93-6.48 21.36 21.36 0 0 0 0-30.63 21 21 0 0 0 -15.93-6.65h-27z"></path>
                    <path d="m309.1 46.78q17.85 0 28.18 9.54t10.32 26.16v52.85h-15.4v-11.9h-.7q-10 14.7-26.6 14.7-14.17 0-23.71-8.4a26.82 26.82 0 0 1 -9.54-21q0-13.31 10.06-21.17t26.86-7.88q14.34 0 23.62 5.25v-3.68a18.33 18.33 0 0 0 -6.65-14.25 22.8 22.8 0 0 0 -15.54-5.87q-13.49 0-21.35 11.38l-14.18-8.93q11.7-16.8 34.63-16.8zm-20.83 62.3a12.86 12.86 0 0 0 5.34 10.5 19.64 19.64 0 0 0 12.51 4.2 25.67 25.67 0 0 0 18.11-7.52q8-7.53 8-17.67-7.53-6-21-6-9.81 0-16.36 4.73c-4.41 3.2-6.6 7.09-6.6 11.76z"></path>
                    <path d="m436 49.58-53.76 123.55h-16.62l19.95-43.23-35.35-80.32h17.5l25.55 61.6h.35l24.85-61.6z"></path>
                  </g>
                  <path
                    d="m141.14 73.64a85.79 85.79 0 0 0 -1.24-14.64h-67.9v27.73h38.89a33.33 33.33 0 0 1 -14.38 21.88v18h23.21c13.59-12.53 21.42-31.06 21.42-52.97z"
                    fill="#4285f4"
                  ></path>
                  <path
                    d="m72 144c19.43 0 35.79-6.38 47.72-17.38l-23.21-18c-6.46 4.38-14.78 6.88-24.51 6.88-18.78 0-34.72-12.66-40.42-29.72h-23.91v18.55a72 72 0 0 0 64.33 39.67z"
                    fill="#34a853"
                  ></path>
                  <path
                    d="m31.58 85.78a43.14 43.14 0 0 1 0-27.56v-18.55h-23.91a72 72 0 0 0 0 64.66z"
                    fill="#fbbc04"
                  ></path>
                  <path
                    d="m72 28.5a39.09 39.09 0 0 1 27.62 10.8l20.55-20.55a69.18 69.18 0 0 0 -48.17-18.75 72 72 0 0 0 -64.33 39.67l23.91 18.55c5.7-17.06 21.64-29.72 40.42-29.72z"
                    fill="#ea4335"
                  ></path>
                </svg> */}
              </div>
            </div>

            {/*
             <div className="menu-social-wrapper socialiconsmenu socialiconsmenuinfo p-2 justify-content-start mt-3 d-flex">
              <a href="https://team.betsmixer.com/" class="socialiconsmenuleft" target="_blank">
                <button className={"btn btn-xs menu-social-btn"}>
                  Our Team
                </button>
              </a>
              <a href="/rakeback" target="_blank" class="socialiconsmenuright">
                <button className={"btn btn-xs menu-social-btn"}>
                  Rakeback
                </button>
              </a>
            </div>*/}
          </div>
        </div>
      </>
    );
  }
}

export default Menu;
