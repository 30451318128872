import React, { Component, createRef } from 'react';
import { debounce } from 'lodash';
import { withRouter } from "react-router-dom";
import { Modal, Row, Col, Image } from "react-bootstrap";
import storage from "../../../Storage";
import socket from "../../../Socket";
import { Event, wait, decode, encode, sendNotfication, secureRandomNumber, generateRandomIntegers } from "../../../Helper";
import C from "../../../Constant";

class Luckyspin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            status: false,
            disabled: false,
            effect: 'zoomIn',
            rolling: false,
            prizeNumber: 8,
            wheelDeg: 0,
            prizeListOrigin: [
            ],
            freeCredits: null,
            leftTimes: null,
            timer: null,
        };
        this.handleShow = this.handleShow.bind(this);
        this.rotate = this.rotate.bind(this);
        this.roll = this.roll.bind(this);
        this.debouncedButtonClick = _.debounce(this.rotate.bind(this), 350);
    }

    componentDidMount() {
        let temp = {};
        let tPL = [];
        let min = 0;
        let max = 0;
        socket.emit(C.LUCKYSPIN_MIN_MAX);
        socket.on(C.LUCKYSPIN_MIN_MAX, (data) => {
            min = _.toNumber(decode(data).min);
            max = _.toNumber(decode(data).max);
            let randomIntegers = generateRandomIntegers(3, 1, 16);
            for (let i = 0; i < 16; i++) {
                temp = {};
                temp.icon = "/assets/images/Tether.png";
                let temp_max = max, temp_min = min;
                let realTemp;
                if (max > 1) {
                    randomIntegers.includes(i) ? (temp_min = max - (max - min) * 0.3, temp_max = max) : (temp_min = min, temp_max = min + (max - min) * 0.7);
                    let srn = secureRandomNumber();
                    realTemp = (srn * (temp_max - temp_min) + temp_min).toString();
                } else realTemp = (secureRandomNumber() * (temp_max - temp_min) + temp_min).toString();
                temp.name = realTemp.length > 6 ? realTemp.slice(0, 6) : realTemp + "0".repeat(6 - realTemp.length);
                tPL.push(temp);
            }
            this.setState({ prizeListOrigin: tPL });
        });

        socket.emit(C.GET_ABLE_TIMES);
        socket.on(C.GET_ABLE_TIMES, (data) => {
            let times = decode(data);
            if (times.luckySpin && times.luckySpin > 0) {
                this.setState({ leftTimes: times.luckySpin });
                const timerI = setInterval(() => {
                    if (this.state.leftTimes - 1 < 0) {
                        this.setState({ leftTimes: null });
                        clearInterval(timerI);
                    } else {
                        this.setState({ leftTimes: (this.state.leftTimes - 1) });
                    }
                }, 1000);
                this.setState({ timer: timerI });
            }
        })
        socket.on(C.IS_GET_SPIN, (data) => {
            let ableLucky = decode(data);
            if (!ableLucky.luckySpin) {
                const rotateElement = document.getElementsByClassName('wheel-bg')[0];
                const computedStyle = window.getComputedStyle(rotateElement);
                const transformValue = computedStyle.getPropertyValue('transform');
                let rotationAngle;
                if (transformValue && transformValue !== 'none') {
                    // Extract the rotation angle from the matrix representation
                    const matrixValues = transformValue.split('(')[1].split(')')[0].split(',');
                    const a = matrixValues[0];
                    const b = matrixValues[1];
                    rotationAngle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                }
                document.getElementsByClassName('wheel-bg')[0].style.animation = null;
                document.getElementsByClassName('wheel-bg')[0].style.transform = 'rotate(' + rotationAngle + 'deg)';
                // const result = Math.floor(Math.random() * this.state.prizeListOrigin.length);
                const result = Math.floor(secureRandomNumber() * this.state.prizeListOrigin.length);
                setTimeout(() => {
                    this.roll(result);
                }, 50);
            } else {
                sendNotfication('You have already got a free spins. You can get free spin in ' + this.formatTime(this.state.leftTimes), 'success', 'bottom-left');
            }
        })
        socket.on(C.UPDATE_SPIN, (data) => {
            let updatedLucky = decode(data);
            if (!updatedLucky.luckySpinUpdated) {
                sendNotfication('There is error on a server. Please retry.', 'success', 'bottom-left');
            } else {
                sendNotfication('You have successfully got ' + this.state.freeCredits + ' USDT.', 'success', 'bottom-left');
            }
        })
        Event.on('lucky_spin', () => {
            this.handleShow();
        })

    }

    componentWillUnmount() {
        socket.off(C.IS_GET_SPIN);
        socket.off(C.GET_ABLE_TIMES);
        socket.off(C.UPDATE_SPIN);
        socket.off(C.LUCKYSPIN_MIN_MAX);
    }

    handleShow(e) {
        this.setState({ show: true, effect: 'zoomIn' });
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    handleClose() {
        this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (event) => {
        if (this.state.show && event.target.classList.contains('modal') && !this.state.rolling) {
            this.handleClose();
        }
    }
    rotate() {
        if (this.state.rolling) {
            return;
        }
        if (this.state.isLogged) {
            socket.emit(C.IS_GET_SPIN);
        } else {
            // this.handleClose();
            // Event.emit('login_form');
            const rotateElement = document.getElementsByClassName('wheel-bg')[0];
            const computedStyle = window.getComputedStyle(rotateElement);
            const transformValue = computedStyle.getPropertyValue('transform');
            let rotationAngle;
            if (transformValue && transformValue !== 'none') {
                // Extract the rotation angle from the matrix representation
                const matrixValues = transformValue.split('(')[1].split(')')[0].split(',');
                const a = matrixValues[0];
                const b = matrixValues[1];
                rotationAngle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
            }
            document.getElementsByClassName('wheel-bg')[0].style.animation = null;
            document.getElementsByClassName('wheel-bg')[0].style.transform = 'rotate(' + rotationAngle + 'deg)';
            // const result = Math.floor(Math.random() * this.state.prizeListOrigin.length);
            const result = Math.floor(secureRandomNumber() * this.state.prizeListOrigin.length);
            setTimeout(() => {
                this.roll(result);
            }, 50);
        }
    }

    roll(result) {
        this.setState({ rolling: true });
        const prizeList = this.state.prizeListOrigin;
        const { wheelDeg } = this.state;
        const newWheelDeg = wheelDeg -
            wheelDeg % 360 +
            6 * 360 +
            (360 - 360 / prizeList.length * result);
        this.setState({ wheelDeg: newWheelDeg });

        setTimeout(() => {
            this.setState({ rolling: false });
            let realIndex = result + 4 > (this.state.prizeListOrigin.length - 1) ? (result + 4) % this.state.prizeListOrigin.length : (result + 4);
            let credit = prizeList[realIndex].name;
            this.setState({ freeCredits: credit });
            if (this.state.isLogged) {
                socket.emit(C.UPDATE_SPIN, encode({ credit: credit }));
                socket.emit(C.GET_ABLE_TIMES);
            } else {
                sendNotfication(`you won ${credit} USDT. Please register and claim the prize`, 'success', 'bottom-left');
                this.handleClose();
                Event.emit('register_form', credit);
            }
        }, 4500);
    }

    formatTime(totalSeconds) {
        const secondsPerMinute = 60;
        const secondsPerHour = secondsPerMinute * 60;
        const secondsPerDay = secondsPerHour * 24;

        const days = Math.floor(totalSeconds / secondsPerDay);
        const hours = Math.floor((totalSeconds % secondsPerDay) / secondsPerHour);
        const minutes = Math.floor((totalSeconds % secondsPerHour) / secondsPerMinute);
        const seconds = totalSeconds % secondsPerMinute;
        if (days == 0) {
            if (hours == 0) {
                if (minutes == 0) {
                    if (seconds == 0) {
                        return "";
                    } else {
                        return `${seconds}S`;
                    }
                } else {
                    return `${minutes}M: ${seconds}S`;
                }
            } else {
                return `${hours}H: ${minutes}M: ${seconds}S`;
            }
        } else {
            return `${days}D: ${hours}H: ${minutes}M: ${seconds}S`;
        }
    };

    formatTopBarTime(totalSeconds) {
        const secondsPerMinute = 60;
        const secondsPerHour = secondsPerMinute * 60;
        const secondsPerDay = secondsPerHour * 24;

        const days = Math.floor(totalSeconds / secondsPerDay);
        const hours = Math.floor((totalSeconds % secondsPerDay) / secondsPerHour);
        const minutes = Math.floor((totalSeconds % secondsPerHour) / secondsPerMinute);
        const seconds = totalSeconds % secondsPerMinute;

        if (days == 0) {
            if (hours == 0) {
                if (minutes == 0) {
                    if (seconds == 0) {
                        return "";
                    } else {
                        return `${seconds}S`;
                    }
                } else {
                    return `${minutes}M: ${seconds}S`;
                }
            } else {
                return `${hours}H: ${minutes}M:`;
            }
        } else {
            return `${days}d: ${hours}h`;
        }
    };

    render() {
        let { t } = this.props;
        return (
            <>
                <div className="luckyspin" onClick={this.handleShow}>
                    <a href="#">
                        <img src="/assets/images/luckyspin.gif" alt="BetsMixer.com" className="d-inline-block align-top" />
                    </a>
                    {
                        this.state.leftTimes ?
                            <div className='spin-top-bar'>{this.formatTopBarTime(this.state.leftTimes)}</div>
                            :
                            null
                    }
                </div>

                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="login-md-modal"
                    className={'modalAuth animated luckyModal ' + this.state.effect}
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body className="auth-modal p-0">
                        <div className="wheel-wrapper">
                            <div
                                id="btn-luckyspin"
                                className="wheel-pointer"
                                onClick={this.debouncedButtonClick}
                                style={{ background: `url('/assets/images/luckyspin/spin22.png')`, backgroundSize: '100% 100%' }}
                            >
                            </div>
                            <div className="wheel-marker" style={{ background: `url('/assets/images/luckyspin/marker.png')`, backgroundSize: '100% 100%' }}></div>
                            <div
                                className="wheel-bg"
                                style={{
                                    transform: 'rotate(' + this.state.wheelDeg + 'deg)',
                                    background: `url('/assets/images/luckyspin/spinner.png')`,
                                    animation: '7s linear 0s infinite normal none running lucky-rotate'
                                }}
                            >
                                <div className="prize-list">
                                    {
                                        this.state.prizeListOrigin.map((prize, index) => {
                                            return (
                                                <div
                                                    className="prize-item-wrapper"
                                                    key={index}
                                                >
                                                    <div
                                                        className="prize-item"
                                                        style={{ transform: 'rotate(' + ((360 / this.state.prizeListOrigin.length) * index) + 'deg)' }}
                                                    >
                                                        <div className="prize-wrap">
                                                            <div className="prize-icon">
                                                                <Image src={prize.icon} fluid />
                                                            </div>
                                                            <div className="prize-name" style={{ transform: 'rotate(-90deg)' }}>
                                                                {prize.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                this.state.leftTimes ?
                                    <div className="spin-bottom">NEXT FREE SPIN IN {this.formatTime(this.state.leftTimes)}</div>
                                    :
                                    null
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withRouter(Luckyspin);
