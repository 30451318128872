import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import { isMobile, wait, Event, encode } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Main extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    //Check player in game
    socket.emit(C.CHECK_MINE);

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(10).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }
    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;

    const help = (
      <>
        <p className={"font-light text-white font-15"}>
          Mines is a game of guessing and luck. You will first need to select a
          bet amount and the number of mines. The higher the mine value, the
          higher the payout. Once you have selected the number of mines and your
          bet amount you will place your bet. You will click each space, taking
          the chance of hitting a mine, or winning that value. You can cash out
          at any time!
        </p>
        <b>Players Tips & Tricks</b>
        <p className={"font-light text-white font-15"}>
          *These tips are submitted by actual players, so please consider with
          caution. Choose all 4 corners! <br />I go in a diagonal pattern, and I
          don’t choose more than 4 squares, then it gets a little risky. I
          always go with the center and 4 corners, seems to be lucky most of the
          time.
        </p>
        <h4 className="text-white text-success">How Can Calculate Profit ?</h4>
        <p className={"font-light text-white font-15"}>
          For the each clicked on the lands, number of mines multiplied by
          amount of the bet and result was divided by 10:{" "}
          <span className="text-secondary">(bet * mines) / 10</span>
        </p>
      </>
    );
    return (
      <>
        <Helmet>
          <title>Mine - Crypto Casino Games</title>
          <meta
            name="description"
            content="Crypto Mines is a game of guessing and luck. You will first need to select a bet amount and the number of mines. The higher the mine value, the higher the payout. Once you have selected the number of mines and your bet amount you will place your bet"
          />
          <meta
            name="og:title"
            content="Play Online Mine - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Mine - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/mine" />
        </Helmet>
        <div>
          <Row className={"animated fadeIn " + ovh}>
            <div className="d-none">Crypto Mine Game Description: {help}</div>
            {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
            <Col className={"m-auto inhousegame"}>
              {/* MENUS */}
              <Card className="mb-0 inhousemenu">
                <Card.Body className="p-0">
                  <Row className="game-wrapper-header align-items-center m-0">
                    <Col md={7} className={"col-7 text-left"}>
                      <p className="mt-2 mb-2 text-white text-upper d-flex">
                        <span className="badge bg-soft-secondary p-2 font-12">
                          mine
                        </span>
                        <span className="badge bg-soft-success p-2 font-12 ml-2">
                          In-House
                        </span>
                        <span className="badge bg-soft-danger p-2 font-12 ml-2">
                          House Edge: <HouseEdge game={"mines"} />%
                        </span>
                      </p>
                      <BankRoll game={"mine"} />
                    </Col>
                    <Col md={5} className={"col-5 text-right inhousebuttons"}>
                      <TopBarGames help={help} gid={gid} image="Mines" />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {this._Mounted ? (
                <>
                  {/* GAME */}
                  <Card className="mb-0">
                    <Card.Body
                      className={"bg-mine-options p-1 roll-panel-mine"}
                      id="roll-panel"
                    >
                      <Row>
                        <Col sm={12} md={10} className={"m-auto"}>
                          <Game engine={this.state.engine} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              ) : (
                <Card className="mb-0">
                  <Card.Body className={"bg-mine-options p-1"} id="roll-panel">
                    <Row>
                      <Col sm={12} md={10} className={"m-auto"}>
                        <div className="game-loader  l-2 text-center">
                          <div
                            className="spinner-grow text-info my-3"
                            role="status"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {/* BETS */}
              <Card className="mb-0">
                <Card.Body className={padding}>
                  <Row>
                    <Col sm={12} md={12} lg={12} className={"m-auto"}>
                      <Bet engine={this.state.engine} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* QUEUE */}
              <Card className="mb-5 lastbetscard">
                <Card.Body className="p-1 mt-4 game-last-bets">
                  <div className="p-2 mt-3 home-last-bets-wrapper">
                    <Bets t={t} />
                  </div>
                </Card.Body>
                <Rakeback t={t} />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Main;
