import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Card, Stack } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import { isMobile, wait, Event, encode } from "../../../Helper";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";
import { gameCoin } from "../../../actions/gameCoin";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      canvas: null,
      padding: "p-1",
      visibility: "hidden",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    this.props.gameCoin();
    engine.coin = this.props.coin;

    Event.on("coin_changed", (coin) => {
      engine.coin = coin;
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;

    this.setState({ canvas: <Game engine={this.state.engine} /> });

    wait(800).then(() => {
      this.setState({ visibility: "visible" });
    });
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh, visibility } = this.state;
    const { t, gid } = this.props;
    const help = (
      <p className="font-light text-white font-15">
        In order to play the game Plinko you will choose a bet amount and the
        place your bet. Each pocket has a multiplier attached that will multiply
        your bet amount. When the ball lands in that pocket you will win that
        bet. <br /> Example: If you bet .10 and the ball lands in the pocked
        x1.00 you will win .10 or 1x your bet amount. If the ball lands in the
        pocket X0.50 you will win .05 or half of your bet amount.{" "}
      </p>
    );
    return (
      <>
        <Helmet>
          <title>Plinko - Crypto Casino Games</title>
          <meta
            name="description"
            content="In order to play the crypto Plinko game you will choose a bet amount and the place your bet. Each pocket has a multiplier attached that will multiply your bet amount. When the ball lands in that pocket you will win that bet."
          />
          <meta
            name="og:title"
            content="Play Online Plinko - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Plinko - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/plinko" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row
              className={"animated fadeIn " + ovh}
              style={{ visibility: visibility }}
            >
              <div className="d-none">{help}</div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            plinko
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"plinko"} />%
                          </span>
                        </p>
                        <BankRoll game={"plinko"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Plinko" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body className={"p-0"} id="roll-panel2">
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <ul className={"plinko-crash"} id={"pc"} />
                        {this._Mounted && <>{this.state.canvas}</>}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} t={this.props.t} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

Index.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { gameCoin })(Index);
