import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>About Us - Crypto Casino Games</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href="/about-us" />
                </Helmet>
                <div>
                    <Card>
                        <Card.Body>
                            <p className="about-us-page">
                                <h2 className="text-secondary">Who We Are</h2>

                                Crash Casino is the place to play the hottest online crypto casino games.
                                As a team, we're passionate about creating a fun and safe space for everyone and anyone who wants to play a mobile bitcoin casino on the go or from the comfort of their own couch.
                                Designed and built by individuals just as passionate about crypto casino games as you are, we've thought of everything so you can sit back, relax, and enjoy the best games available.
                                We've adopted and designed a wide range of games in a format that makes them fun and easy to play. And with the rising popularity of cryptocurrency, we thought it made perfect sense to accept a variety of cryptocurrencies via our casino.
                                If you love playing the world's top casino games for real money, then you'll love Crash Casino.

                                <h2 className="text-secondary">Our Games—What You Can Expect</h2>

                                Crash Casino has the industry's hottest online casino games including Crash and Dice, alongside classics such as Blackjack and well over 100 slots to play at your own pace.
                                Our goal is to make our online casino fun, exciting, and easily accessible.
                                When you join our platform, you'll get access to hundreds of slot games that you'll have a blast exploring and playing.
                                We hope you'll enjoy every second you're at Crash Casino.

                                <h2 className="text-secondary">Our Mission</h2>

                                Our mission is to stand out from the countless crypto casinos online by providing a transparent and fun place to play. When you play at our no minimum deposit casino, you can be confident we pay out every player in full if you win. Our platform uses the industry's latest technology to ensure every game is fair and enjoyable every time you play. If you have Bitcoin or any other cryptocurrency you'd like to play with, we've made it our mission to be the best option for online gaming.
                                Why Should You Make Crash Casino Your Go-to Online Casino?

                                Crash Casino is not just another online casino. We're the center of fair, transparent, and fun cryptocurrency gaming online. Our users get an instant payout for their winnings—which is virtually unheard of in the industry. We also have a quick and easy sign-up process so you can start playing your favorite games as soon as you like. With multiple-crypto coins supported via our platform, we make sure playing with us is fast and hassle-free.
                                The health and safety of our users are equally important. That's why our team reaches out to any user who we see is gambling more frequently than our average users, ensuring they aren't suffering from a gambling addiction.
                                For fast, fun, and fair cryptocurrency-based online gaming, sign up for Crash Casino, today.
                                Sign Up Now.
                            </p>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
