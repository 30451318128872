import React from 'react';
import { Row, Col, Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SITE_URL, isMobile } from "../../../../Helper";
import Translation from "../Translation";
import Verify from "../Sidebar/Verify";
import Redeem from '../../../Components/User/Redeem/index';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: isMobile()
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ mobile: isMobile() })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.mobile ?
                    <div className="betsmixer-footer font-light bg-footer">
                        <Row className="no-gutters m-0">
                            {/* <Col sm="3" className="footer-mark h-100 footer-s-mark"> */}
                            <Col className="footer-mark">
                                <Stack className='justify-content-between h-100 footer-mark-wrapper'>
                                    <img src="/assets/images/betsmixer-g.svg" alt="logo" className="img-fluid footer-brand-mark" />
                                    <p className="mt-2 footerinfo">Operated by <a href="https://bmi-holding.com" target="_blank">BMI Holding Ltd.</a> Reg. 215319, licensed to conduct online gaming operations by the Government of Curacao under sub-license <a href="https://betsmixer.com/license_betsmixer.png" target="_blank">No. 158182</a></p>
                                    <div className='d-flex align-items-center footercopyright'>
                                        <div className='footer-b-mark'>
                                            <img src="/assets/images/footer/footer-i1.svg" alt="logo" className="img-fluid" />
                                        </div>
                                        <div className='footer-b-mark-t'>
                                            <span>BetsMixer.com © 2024</span>
                                        </div>
                                    </div>
                                </Stack>
                            </Col>
                            <Col className='footer-s-submenu h-100'>
                                <Stack className='justify-content-between h-100 footer-links'>
                                    <Row className="bottom-footer-menu d-flex justify-content-center" gap={2}>
                                        <Col md="auto">
                                            <Link to="/affiliate">{t('affiliate')}</Link>
                                        </Col>
                                        <Col md="auto">
                                            <Link to={'/rules?tab=1'}>
                                                {t('privacy')}
                                            </Link>
                                        </Col>
                                        <Col md="auto" style={{display: "none"}}>
                                            <Link to={'/rules?tab=1'}>
                                                {t('terms_conditions')}
                                            </Link>
                                        </Col>
                                        <Col md="auto">
                                            <Verify t={t} />
                                        </Col>
                                        <Col md="auto">
                                            {/* <Link to={'/rakeback'}>
                                                {t('rewards')}
                                            </Link> */}
                                            {/* <Redeem t={t} /> */}

                                        </Col>
                                        {/*
                                        <Col md="auto">
                                            <Link to={'/report-bug'}>
                                                {t('report')}
                                            </Link>
                                        </Col> */}
                                        <Col md="auto">
                                            <a href="https://betsmixer.com/betsmixer_kyc_watermark.pdf" target="_blank">
                                                KYC
                                            </a>
                                        </Col>
                                        <Col md="auto">
                                           <a href="https://ten.gg/b/betsmixercom/jobs" target="_blank">
                                                Jobs
                                            </a>
                                        </Col>
                                        <Col md="auto">
                                            <Link to={'/help-center'}>
                                                {t('help')}
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row className="footer-coin-mark d-flex justify-content-center">
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin12.png" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin14.png" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin3.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin3-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin4.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin4-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin5.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin5-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin11.png" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin13.png" alt="Coin" />
                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin6.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin6-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    </Row>
                                </Stack>
                            </Col>

                            {/* <Col sm="3" className="h-100 footer-social-wrapper"> */}
                            <Col className="footer-social-wrapper">
                                <Stack direction="horizontal" className='justify-content-end'>
                                    <div className="footer-social-links d-flex m-0 gap-footer">
                                        <div className="translationfooter">
                                            <Translation />
                                        </div>
                                        <a href="https://www.linkedin.com/company/betsmixer/" className='social-links col-md-auto' target="_blank">
                                                <i className="mdi mdi-linkedin text-muted text-drop"></i>
                                        </a>

                                        <a href="https://t.me/betsmixer" className='social-links col-md-auto' target="_blank">
                                                <i className="mdi mdi-telegram text-muted text-drop"></i>
                                        </a>

                                        <a href="https://twitter.com/betsmixer" className='social-links col-md-auto' target="_blank">
                                                {/* <img className="img-fluid" src="/assets/images/sidebar/social/twitter.svg" alt="Coin" /> */}
                                                <i className="mdi mdi-twitter text-muted text-drop"></i>
                                        </a>
                                        {/* <a href="https://www.linkedin.com/company/betsmixer" className='social-links col-md-auto' target="_blank">
                                                <img className="img-fluid" src="/assets/images/sidebar/social/link.svg" alt="Coin" />
                                        </a> */}
                                    </div>
                                </Stack>
                            </Col>
                            <Col className="responsiblegaming">
                                <Stack>
                                    <Row className="d-flex responsible-wrapper">
                                        <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-mastercard-visa" src="/assets/images/verified-by-visa-mastercard-min.png"  />
                                        </Col>
                                        <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid img-reponsible-gaming" src="https://app.betsmixer.com/assets/icons/responsible_gaming.svg" alt="Coin" />
                                        </Col>
                                        <Col md="auto" className='footer-coin-wrapper'>
                                            <a href="https://betsmixer.com/license_betsmixer.png" target="_blank">
                                                <img className="img-fluid iconcuracao" src="https://app.betsmixer.com/assets/icons/betsmixer-license.png" alt="Coin" />
                                            </a>
                                        </Col>
                                    </Row>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                :
                    <div className="betsmixer-footer font-light bg-footer">
                        <Row className="no-gutters m-0">
                            {/* <Col sm="3" className="footer-mark h-100 footer-s-mark"> */}
                            <Col className="footer-mark">
                                <Stack className='justify-content-between h-100'>
                                    <img src="/assets/images/betsmixer-g.svg" alt="logo" className="img-fluid footer-brand-mark" />
                                    <p className="mt-2 footerinfo">Operated by <a href="https://bmi-holding.com" target="_blank">BMI Holding Ltd.</a> Reg. 215319 and <br></br><a href="#" target="_blank">Red Key Services Ltd.</a>, licensed to online gaming operations by the Government of Curacao <br></br> under sub-license <a href="https://betsmixer.com/license_betsmixer.png" target="_blank">No. 158182</a></p>
                                    <div className='d-flex align-items-center footercopyright'>
                                        <div className='footer-b-mark'>
                                            <img src="/assets/images/footer/footer-i1.svg" alt="logo" className="img-fluid" />
                                        </div>
                                        <div className='footer-b-mark-t'>
                                            <span>BetsMixer.com © 2024</span>
                                        </div>
                                    </div>
                                </Stack>
                            </Col>
                            <Col className='footer-s-submenu'>
                                <Stack className='justify-content-between h-100 footer-links'>
                                    <Row className="bottom-footer-menu d-flex justify-content-center" gap={2}>
                                        <Col md="auto">
                                            <Link to="/affiliate">{t('affiliate')}</Link>
                                        </Col>
                                        <Col md="auto">
                                            <Link to={'/rules?tab=1'}>
                                                {t('privacy')}
                                            </Link>
                                        </Col>
                                        <Col md="auto" style={{display: "none"}}>
                                            <Link to={'/rules?tab=1'}>
                                                {t('terms_conditions')}
                                            </Link>
                                        </Col>
                                        <Col md="auto">
                                            <Verify t={t} />
                                        </Col>
                                       {/* 
                                        <Col md="auto">
                                            {/* <Link to={'/rakeback'}>
                                                {t('rewards')}
                                            </Link>
                                            <Redeem t={t}/>
                                        </Col>
                                        
                                        <Col md="auto">
                                            <Link to={'/report-bug'}>
                                                {t('report')}
                                            </Link>
                                        </Col> */}
                                        <Col md="auto">
                                            <a href="https://betsmixer.com/betsmixer_kyc_watermark.pdf" target="_blank">
                                                KYC
                                            </a>
                                        </Col>
                                        <Col md="auto">
                                            <Link to={'/help-center'}>
                                                Responsibility
                                            </Link>
                                        </Col>
                                        <Col md="auto">
                                           <a href="https://ten.gg/b/betsmixercom/jobs" target="_blank">
                                                Jobs
                                            </a>
                                        </Col>
                                        <Col md="auto" className='d-none'>
                                            <Link to={'/blog'}>
                                                Blog
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row className="footer-coin-mark d-flex justify-content-center">
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin12.png" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin14.png" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin3.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin3-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin4.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin4-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin5.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin5-a.svg" alt="Coin-Hover" />

                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin11.png" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin13.png" alt="Coin" />
                                    </Col>
                                    <Col md="auto" className='footer-coin-wrapper'>
                                        <img className="img-fluid" src="/assets/images/footer/coin6.svg" alt="Coin" />
                                        <img className="img-fluid img-top" src="/assets/images/footer/coin6-a.svg" alt="Coin-Hover" />
                                    </Col>
                                </Row>
                                </Stack>
                            </Col>

                            {/* <Col sm="3" className="h-100 footer-social-wrapper"> */}
                            <Col className="footer-social-wrapper">
                                <Stack direction="horizontal" className='justify-content-end'>
                                    <div className="footer-social-links d-flex m-0 gap-footer">
                                        <div className="translationfooter">
                                            <Translation />
                                        </div>
                                        <a href="https://www.linkedin.com/company/betsmixer/" className='social-links col-md-auto' target="_blank">
                                            <i className="mdi mdi-linkedin text-muted text-drop"></i>
                                        </a>
                                        <a href="https://t.me/betsmixer" className='social-links col-md-auto' target="_blank">
                                            <i className="mdi mdi-telegram text-muted text-drop"></i>
                                        </a>
                                        <a href="https://twitter.com/betsmixer" className='social-links col-md-auto' target="_blank">
                                            {/* <img className="img-fluid" src="/assets/images/sidebar/social/twitter.svg" alt="Coin" /> */}
                                            <i className="mdi mdi-twitter text-muted text-drop"></i>
                                        </a>
                                        {/* <a href="https://www.linkedin.com/company/betsmixer" className='social-links col-md-auto' target="_blank">
                                            <img className="img-fluid" src="/assets/images/sidebar/social/link.svg" alt="Coin" />
                                        </a> */}
                                    </div>
                                </Stack>

                                <div class="footerbusiness">
                                    <p className="mt-2 footerinfo">Inquiries:
                                    <i class="mdi mdi-message-text-outline text-muted businesslinks text-drop"></i> <a href="mailto:info@betsmixer.com" target="_blank">
                                    <span>info@betsmixer.com</span>
                                    </a>
                                    <br></br>
                                    Manager:
                                    <i class="mdi mdi-message-outline text-muted businesslinks text-drop"></i> <a href="https://t.me/betsmixer_partners" target="_blank">
                                    <span>@betsmixer_partners</span>
                                    </a>
                                    </p>
                                </div>
                            </Col>
                            <Col className="responsiblegaming">
                                <Stack>
                                    <Row className="d-flex responsible-wrapper">
                                        <Col md="auto" className='footer-coin-wrapper'>
                                            <img className="img-mastercard-visa" src="/assets/images/verified-by-visa-mastercard-min.png"  />
                                        </Col>
                                        <Col md="auto" className='footer-coin-wrapper'>
                                            <img className="img-fluid img-reponsible-gaming" src="https://app.betsmixer.com/assets/icons/responsible_gaming.svg" alt="Coin" />
                                        </Col>
                                        <Col md="auto" className='footer-coin-wrapper'>
                                            <a href="https://betsmixer.com/license_betsmixer.png" target="_blank">
                                                <img className="img-fluid iconcuracao" src="https://app.betsmixer.com/assets/icons/betsmixer-license.png" alt="Coin" />
                                            </a>
                                        </Col>
                                    </Row>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                }
            </>
        );
    }
}
