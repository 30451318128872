import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import ReactTooltip from "react-tooltip";

import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";
import AdsClick from "@mui/icons-material/AdsClick";

import Leaderboard from "./Leaderboard";
import PromoMaterial from "./PromoMaterial";
import storage from "../../../Storage";
import { getUID, SITE_URL } from "../../../Helper";
import Estimator from "./Estimator";
import Terms from "./Terms";
import List from "./List";
import socket from "../../../Socket";
import C from "../../../Constant";
import {
  __,
  encode,
  decode,
  getElement,
  sendNotfication,
  toUSDFormat,
  Event,
  isMobile,
} from "../../../Helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

function TabsContainer(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div>
          <AppBar
            position="static"
            color="default"
            className="affiliatetabs p-4"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="action tabs example"
            >
              <Tab
                className="text-white buttonaffiliate buttonsaff"
                label="Affiliate"
                {...a11yProps(0)}
                icon={<AutoAwesomeIcon />}
              />
              <Tab
                className="text-white buttonleaderboard buttonsaff"
                label="Leaderboard"
                icon={<StarPurple500Icon />}
                {...a11yProps(1)}
              />
              <Tab
                className="text-white buttonpromo buttonsaff ml-2"
                label="Promo"
                icon={<AdsClick />}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={"x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel
              className="TabPanelAff"
              value={value}
              index={0}
              dir={"ltr"}
            >
              <Affiliate t={props.t} />
            </TabPanel>
            <TabPanel
              className="TabPanelAff"
              value={value}
              index={1}
              dir={"ltr"}
            >
              <Leaderboard t={props.t} />
            </TabPanel>
            <TabPanel
              className="TabPanelAff"
              value={value}
              index={2}
              dir={"ltr"}
            >
              <PromoMaterial t={props.t} />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
}

class Affiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: storage.getKey("token") ? storage.getKey("token") : null,
      uid: Cookies.get("uid"),
      username: storage.getKey("name"),
      logged: false,
      loading: false,
      users: 0,
      affilate: 0,
      withdraw: false,
      disabled: true,
      walletAddress: "",
    };
  }

  componentWillMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.state.token !== null) {
      this.setState({ logged: true });
    }
  }

  componentDidMount() {
    if (this.state.logged) {
      socket.emit(C.GET_AFFILATE);
      socket.on(C.GET_AFFILATE, (data) => {
        if (data !== null) {
          let { affilate, users } = decode(data);
          let amount = toUSDFormat(affilate);
          this.setState({ affilate: amount, users: users ? users : 0 });
        }
      });

      socket.on(C.SUBMIT_NEW_AFFILIATE_WITHDRAWL, (data) => {
        const { status } = decode(data);

        this.setState({ loading: false });

        sendNotfication(status, "success", "top-right");
      });

      Event.on("active_affilate", () => {
        this.setState({ disabled: false });
      });
    }

    if (isMobile()) {
      this.setState({ padding: "p-1", paddingHeader: "px-2" });
    }
  }

  componentWillUnmount() {
    socket.off(C.GET_AFFILATE);
  }

  handleInputChange = (e) => {
    let { value } = e.target;
    this.setState({ walletAddress: value });
  };

  emitWithdrawModal = () => {
    if (this.state.logged) {
      this.setState({ withdraw: !this.state.withdraw });
    } else Event.emit("register_form");
  };

  formatAmount(number) {
    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }

  copyAddress(id) {
    var address = getElement("#aff_" + id);
    address.select();
    address.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    sendNotfication(this.props.t("copied"), "success", "top-right");
  }

  submitForm(e) {
    e.preventDefault();

    this.setState({ loading: true });

    socket.emit(
      C.SUBMIT_NEW_AFFILIATE_WITHDRAWL,
      encode({
        wallet: this.state.walletAddress,
      })
    );
  }

  render() {
    let { logged, affilate } = this.state;
    affilate = this.formatAmount(affilate);
    const { t } = this.props;
    return (
      <>
        <ReactTooltip />
        <Helmet>
          <title>Affiliate and Referrals - BetsMixer.com</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/affiliate" />
        </Helmet>

        <div>
          <div
            style={{ background: "transparent", backgroundSize: "cover" }}
            className={"p-4 wheel-content affiliate-content"}
          >
            <Terms t={t} />

            <h2
              className={
                "text-center text-upper text-warning font-weight-bold affiliatetextheader"
              }
            >
              Affiliate Partnership
            </h2>

            <img
              src="/assets/images/affilate.png"
              className="affilateimg"
              alt=""
            />

            <p
              className={"text-center text-upper text-white"}
              style={{ marginBottom: "0px" }}
            >
              Invite your friends
              <span className="text-yellow affilaterevenue">
                {t("get")} 15%
              </span>{" "}
              from all deposits
            </p>

            <Row>
              <Col className={"m-auto affilatecards"}>
                <Card className="maincardside maincardsideleft">
                  <Card.Body
                    className={"rounded text-center"}
                    style={{ background: "#243042" }}
                  >
                    {t("total_reward_earned")}:
                    <p className="text-yellow">${affilate}</p>
                    <i></i>
                  </Card.Body>
                </Card>

                <Card className="maincard">
                  <Card.Body
                    className={"rounded text-center"}
                    style={{ background: "#243042" }}
                  >
                    <label className="text-white text-upper">
                      <img
                        src="/assets/images/t1.gif"
                        className="affilate-imageStyle"
                      />
                      <span className="affilate-linktext">
                        {t("your_link")}
                      </span>
                    </label>

                    {logged ? (
                      <div className="d-flex affiliatebuttons">
                        <input
                          id={`aff_${this.state.uid}`}
                          type="text"
                          value={
                            "https://" +
                            SITE_URL +
                            "/aff/" +
                            this.state.username
                          }
                          className={"form-control"}
                          readOnly
                        />
                        <button
                          onClick={(e) => this.copyAddress(this.state.uid)}
                          type="button"
                          className="btn bg-cs no-shadow btn-clipboard clipboardaffiliate buttonclipboard hv"
                        >
                          <i className="mdi mdi-checkbox-multiple-blank-outline"></i>
                        </button>
                      </div>
                    ) : (
                      <input
                        type="text"
                        value={t("please_login_to_see_your_link") + "."}
                        className={"form-control"}
                        style={{ pointerEvents: "none" }}
                        readOnly
                      />
                    )}
                    <button
                      type="button"
                      className="btn affilateterms withdrawaff"
                      onClick={this.emitWithdrawModal}
                      disabled={this.state.disabled}
                    >
                      <img
                        src="/assets/images/usdt.png"
                        className="mini-coin-8"
                        alt=""
                      />
                      {t("withdraw_affiliate")}
                      <i></i>
                    </button>

                    {this.state.withdraw && (
                      <>
                        <Modal
                          size={"md"}
                          backdrop={"static"}
                          centered={true}
                          scrollable={false}
                          show={this.state.withdraw}
                          onHide={this.emitWithdrawModal}
                          aria-labelledby="wallet-md-modal"
                          className="wallet-modal-wrapper"
                        >
                          <Modal.Header className={this.state.paddingHeader}>
                            <button
                              type="button"
                              className="close p-2 closeaffiliatebtn"
                              onClick={this.emitWithdrawModal}
                            >
                              <i className={"mdi mdi-close"} />
                            </button>
                          </Modal.Header>
                          <Modal.Body
                            className={
                              this.state.padding + " wallet- "
                            }
                          >
                            <Form onSubmit={(e) => this.submitForm(e)}>
                              <label htmlFor="wallet" className="text-white">
                                USDT Wallet Address
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={this.state.walletAddress}
                                  className="form-control bgl2"
                                  name="wallet"
                                  id="wallet"
                                  placeholder=""
                                  onChange={this.handleInputChange}
                                  required={true}
                                  autoComplete={"off"}
                                />
                              </div>
                              <div className="alert alert-warning-shadow mt-1 fade bg-soft-warning bg-soft-warning-affiliate show">
                                <div className="alert-text font-12">
                                  <i className="dripicons-warning text-warning" />{" "}
                                  Make sure you enter an address on the TRC-20
                                </div>
                              </div>

                              <h4 className="text-center text-yellow text-affiliate-revenue">
                                Total Earned: ${affilate}
                              </h4>

                              <button
                                className="btn btn-s-4 btn-sm px-4 withdrawalbutton mb-2"
                                type="submit"
                                disabled={this.state.loading}
                              >
                                <i className="dripicons-wallet coinwalleticon"></i>
                                {t("request_withdrawl")}
                              </button>
                            </Form>
                          </Modal.Body>
                        </Modal>
                      </>
                    )}
                  </Card.Body>
                </Card>

                <Card className="maincardside maincardsideright">
                  <Card.Body
                    className={"rounded text-center"}
                    style={{ background: "#243042" }}
                  >
                    {t("total_friends_referred")}:
                    <p className="text-yellow">
                      {logged ? this.state.users : 0}
                    </p>
                    <i></i>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <Row>
            <Col sm={12} xl={12} md={12} className={"mx-auto mt-3"}>
              <Card>
                <Card.Body className="affiliatebox">
                  <h5 className="text- mb-3 estimatortitle">
                    Estimator earnings
                    <a
                      data-tip={"Calculate of your profit from each deposit"}
                      data-place="right"
                    >
                      <i className="mdi mdi-information align-middle ml-2 font-17 pnt" />
                    </a>
                  </h5>
                  <Estimator t={t} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              sm={12}
              xl={12}
              md={12}
              className={"mx-auto mt-3 affiliateboxtable"}
            >
              <Card>
                <Card.Body className="affiliatebox">
                  {!logged ? (
                    <div className="text-center">
                      [ {t("login_to_your_stats")} ]
                    </div>
                  ) : (
                    <List t={t} />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default TabsContainer;
