import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isMobile, wait, Event, encode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Table from "./components/Table";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

import "../../../Static/css/videoPoker.css";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
      isMobile: isMobile() ? true : false,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    //Check player in game
    wait(1000).then(() => {
      socket.emit(C.CHECK_VIDEOPOKER);
    });

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (this.state.isMobile) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh, isMobile } = this.state;
    const { t, gid } = this.props;

    const help = (
      <p className="font-light text-white font-15">
        Video poker has many variants, but all are based on one of poker’s
        simplest varieties, five-card draw. You are dealt a five-card poker
        hand, then given one opportunity to keep the cards you wish to keep and
        discard the ones you don’t wish to retain, receiving new cards in
        return. This then makes up your final hand.
        <b>Video Poker Tips & Tricks</b> <br />
        Hit deal to receive your initial five-card poker hand <br />
        Discard cards you don’t deem useful (up to all five can be discarded){" "}
        <br />
        Receive replacement cards from the same ‘virtual deck’.
        <br />
        Any winnings are paid out according to the paytable. <br />
      </p>
    );

    return (
      <>
        <Helmet>
          <title>Video Poker - Crypto Casino Games</title>
          <meta
            name="description"
            content="Crypto Video poker Game has many variants, but all are based on one of poker’s simplest varieties, five-card draw.
            You are dealt a five-card poker hand, then given one opportunity to keep the cards you wish to keep and discard the ones you don’t wish."
          />
          <meta
            name="og:title"
            content="Play Online Video Poker - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Video Poker - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/videopoker" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">
                Crypto Video Poker Game Description: {help}
              </div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            video poker
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"videopoker"} />%
                          </span>
                        </p>
                        <BankRoll game={"videopoker"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames
                          gid={gid}
                          help={help}
                          image="Video Poker"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body
                    className={"bg-dice-options p-1 animated fadeIn"}
                    id="roll-panel"
                  >
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        {isMobile ? (
                          <Table engine={this.state.engine} />
                        ) : (
                          <Game engine={this.state.engine} />
                        )}
                      </Col>
                      <Col sm={12} md={9} className={"m-auto"}>
                        {isMobile ? (
                          <Game engine={this.state.engine} />
                        ) : (
                          <Table engine={this.state.engine} />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={10} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>

                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
