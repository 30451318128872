import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { __, isMobile, wait, Event, decode } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import storage from "../../../../Storage";

const popularGames = ["crash", "classic-dice", "blackjack", "wheel"];
class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      games: [],
      mobile: isMobile(),
      isLogged: storage.getKey("logged") !== null ? true : false,
    };
    
  }

  componentDidMount() {
    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize());
    socket.off(C.GAMES);
  }

  emitDepostModal = () => {
    if (this.state.isLogged) Event.emit("e_sevice_deposit");
    else Event.emit("register_form");
  };

  render() {
    const { t } = this.props;
    return (
      <>
      <Swiper
      id="service_swiper"
        spaceBetween={24}
        slidesPerView= {2}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //     clickable: true,
        //     el: ".service-swiper-pagination",
        // }}
        modules={[Pagination, Autoplay]}
        className="home-services"
        breakpointsBase="container"
        breakpoints={{
          300: {
            slidesPerView: 2,
            centeredSlides: true,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 2,
            centeredSlides: true,
            spaceBetween: 20
          },
          815: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 24
          },
          1100: {
            spaceBetween: 24,
            slidesPerView: 4,
            centeredSlides: false
          }
        }}

      >
        <SwiperSlide>
          <Link to="/promotion">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/2.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_21")}
                  <br />
                  {t("home_services_t_b_22")}
                </p>
                <p className="service-t-s">
                  {t("home_services_t_s_21")}
                  <br />
                  {t("home_services_t_s_22")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/affiliate">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/3.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">{t("home_services_t_b_3")}</p>
                <p className="service-t-s">
                  {t("home_services_t_s_31")}
                  <br />
                  {t("home_services_t_s_32")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/live">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/10.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_51")}
                  <br />
                  {t("home_services_t_b_52")}
                </p>
                <p className="service-t-s">
                  {t("home_services_t_s_51")}
                  <br />
                  {t("home_services_t_s_52")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/daily-contest">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/7.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_41")}
                  <br />
                  {t("home_services_t_b_42")}
                  </p>
                <p className="service-t-s">
                  {t("home_services_t_s_41")}
                  <br />
                  {t("home_services_t_s_42")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/vip-levels">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/12.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_71")}
                  <br />
                  {t("home_services_t_b_72")}
                  </p>
                <p className="service-t-s">
                  {t("home_services_t_s_71")}
                  <br />
                  {t("home_services_t_s_72")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        {/* //dummy slides for swiper to run correctly */}
        <SwiperSlide>
          <Link to="#" onClick={this.emitDepostModal}>
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/1.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">{t("home_services_t_b_1")}</p>
                <p className="service-t-s">
                  {t("home_services_t_s_11")}
                  <br />
                  {t("home_services_t_s_12")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/promotion">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/2.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_21")}
                  <br />
                  {t("home_services_t_b_22")}
                </p>
                <p className="service-t-s">
                  {t("home_services_t_s_21")}
                  <br />
                  {t("home_services_t_s_22")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/affiliate">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/3.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">{t("home_services_t_b_3")}</p>
                <p className="service-t-s">
                  {t("home_services_t_s_31")}
                  <br />
                  {t("home_services_t_s_32")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/9.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_51")}
                  <br />
                  {t("home_services_t_b_52")}
                </p>
                <p className="service-t-s">
                  {t("home_services_t_s_51")}
                  <br />
                  {t("home_services_t_s_52")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/daily-contest">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/7.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_41")}
                  <br />
                  {t("home_services_t_b_42")}
                  </p>
                <p className="service-t-s">
                  {t("home_services_t_s_41")}
                  <br />
                  {t("home_services_t_s_42")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/vip-levels">
            <div className="game2-image orange position-relative">
              <img
                src="/assets/images/services/12.png"
                className="img-fluid w-100"
                alt=""
              />
              <Stack className="position-absolute w-100 h-100 service-t">
                <p className="service-t-b">
                  {t("home_services_t_b_71")}
                  <br />
                  {t("home_services_t_b_72")}
                  </p>
                <p className="service-t-s">
                  {t("home_services_t_s_71")}
                  <br />
                  {t("home_services_t_s_72")}
                </p>
              </Stack>
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
     
      
      {/* <div class="service-swiper-pagination"></div> */}
      </>
    )
  }
}

export default Services;
