import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Sports from "./Sports";

// import Tabs from "./Tabs";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    componentWillUnmount() {
    }

    render() {
        const { t } = this.props;
        return <>
            <Helmet>
                <title>Sports Bet - Crypto Casino Games</title>
                <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Casino Sports" />
                <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <link rel="canonical" href="/sports" />
            </Helmet>
            <div className="loading-container">
            {
                this.state.loading ?
                    <div className="text-center">
                        <img src="assets/images/loader-sports-2.gif" alt="..."/>
                    </div>
                    :
                    <>
                        <Sports t={t} />
                    </>
            }
            </div>
        </>
    }
}

export default Index;
