import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { toCurrencyFomatter } from '../../../Helper';
import Rakeback from '../Parts/Home/Rakeback';

export default class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targets: [0, 0, 0, 0, 0]
        };
    }

    targetUpdate = (data) => {
        this.setState({targets: [...data]});
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>Rakeback - Crypto Casino Games</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href="/rakeback" />
                </Helmet>
                <div className='rakeback-wrapper'>
                    <Card className="levbg bg-dark text-white p-4 wheel-content rewardsdiv rakebackbox card">
                        <img src="/assets/images/vipbull.png" alt="BetsMixer.com" className="bull-rewards" />

                        <Card.Body>
                            <h1 className="text-center rewardstitle">{t('level_up_rakeback')}</h1>
                            <Card.Text className="font-17 font-weight-bold text-center">
                                {t('wager_more_and_achieve_up_rakeback_level')}!
                            </Card.Text>
                            <div className={"medals-view mt-5"}>
                                <Row>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-2.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple badge-purple-rakeback px-3">1st {t('level')}</span>
                                                </div>
                                                {toCurrencyFomatter(this.state.targets[0])} ➜ <span className="text-warning">{t('rewards')} 1% <i className="mdi mdi-crown crownicon text-muted mr-2 text-drop"></i></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-22.png" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple badge-purple-rakeback px-3">2nd {t('level')}</span>
                                                </div>
                                                {toCurrencyFomatter(this.state.targets[1])} ➜ <span className="text-warning">{t('rewards')} 5% <i className="mdi mdi-crown crownicon text-muted mr-2 text-drop"></i></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-3.svg" height="45" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple badge-purple-rakeback px-3">3rd {t('level')}</span>
                                                </div>
                                            {toCurrencyFomatter(this.state.targets[2])} ➜ <span className="text-warning">{t('rewards')} 10% <i className="mdi mdi-crown crownicon text-muted mr-2 text-drop"></i></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="media mb-2">
                                            <img src="/assets/images/badges/badge-1.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple badge-purple-rakeback px-3">4th {t('level')}</span>
                                                </div>
                                                {toCurrencyFomatter(this.state.targets[3])} ➜ <span className="text-warning">{t('rewards')} 15% <i className="mdi mdi-crown crownicon text-muted mr-2 text-drop"></i></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="12">
                                        <div className="media my-2">
                                            <img src="/assets/images/badges/badge-1.svg" height="60" className="mr-3" />
                                            <div className="media-body align-self-center">
                                                <div className="mb-2">
                                                    <span className="badge badge-purple badge-purple-rakeback px-3">5th {t('level')}</span>
                                                </div>
                                                {toCurrencyFomatter(this.state.targets[4])} ➜ <span className="text-warning">{t('rewards')} 20% <i className="mdi mdi-crown crownicon text-muted mr-2 text-drop"></i></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="rakebackpage rakebackvippage">
                    <Rakeback t={t} targetUpdate={this.targetUpdate} />
                </div>
            </>
        );
    }
}
