import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isMobile, wait, Event, encode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import Loading from "../Loading";
import "../../../Static/css/blackjack.css";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    //Check player in game
    wait(1000).then(() => {
      socket.emit(C.CHECK_BLACKJACK);
    });

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;
    const help = (
      <p className="font-light text-white font-15">
        Objective of the game: Each participant attempts to beat the dealer by
        getting a count as close to 21 as possible, without going over 21. Card
        values and scoring: It is up to each individual player if an ace is
        worth 1 or 11.
        <br /> Face cards are 10 and any other card is its pip value. Betting:
        Before the deal begins, each player places a bet in front of them in the
        designated area. The shuffle and cut: The dealer thoroughly shuffles
        portions of the pack until all the cards have been mixed and combined.
        The dealer designates one of the players to cut, and the plastic insert
        card is placed so that the last 60 to 75 cards or so will not be used.
        <br /> The deal: When you have placed your bet, the dealer will give you
        one card face up, and then one card face up to themselves. Another round
        of cards is then dealt face up to you, but the dealer takes the second
        card face down. Thus, each player except the dealer receives two cards
        face up, and the dealer receives one card face up and one card face
        down. The play: You will decide whether to "stand" (not ask for another
        card) or "hit" (ask for another card in an attempt to get closer to a
        count of 21, or even hit 21 exactly).
        <br /> Thus, you may stand on the two cards originally dealt to you, or
        you may ask the dealer for additional cards, one at a time, until
        deciding to stand on the total (if it is 21 or under) or goes "bust" (if
        it is over 21). In the latter case, the player loses, and the dealer
        collects the bet wagered. The combination of an ace with a card other
        than a ten-card is known as a "soft hand," because the player can count
        the ace as a 1 or 11, and either draw cards or not. For example with a
        "soft 17" (an ace and a 6), the total is 7 or 17.
        <br /> While a count of 17 is a good hand, the player may wish to draw
        for a higher total. If the draw creates a bust hand by counting the ace
        as an 11, the player simply counts the ace as a 1 and continues playing
        by standing or "hitting" (asking the dealer for additional cards, one at
        a time).
      </p>
    );
    return (
      <>
        <Helmet>
          <title>BlackJack - Crypto Casino Games</title>
          <meta
            name="keywords"
            content="Crypto BlackJack Game, Online BlackJack Game, Bitcoin BlackJack Game, Blockchain BlackJack Game, Best Crypto BlackJack Game, BetsMixer.com BlackJack"
          />
          <meta
            name="description"
            content="Objective of the Crypto Blackjack Game: Each participant attempts to beat the dealer by getting a count as close to 21 as possible, without going over 21. Card values and scoring: It is up to each individual player if an ace is worth 1 or 11."
          />
          <meta
            name="og:title"
            content="Play Online BlackJack - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online BlackJack - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/blackjack" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">Crypto BlackJack Description: {help}</div>
              <Col className={"m-auto inhousegame"}>
                {/* TOP MENUS */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            black jack
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"blackjack"} />%
                          </span>
                        </p>
                        <BankRoll game={"blackjack"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="BlackJack" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body className={"bg-dice-options p-1"} id="roll-panel">
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <Game engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
