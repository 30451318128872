import React from "react";
import {
  Dropdown,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";
import i18n from "i18next";
import { isMobile, Event, __ } from "../../../../Helper";
import storage from "../../../../Storage";

class Translation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [
        { code: "us", lng: "en", title: "English" },
        { code: "es", lng: "es", title: "Spanish" },
        { code: "ru", lng: "ru", title: "Russian" },
        { code: "fr", lng: "fr", title: "French" },
        { code: "de", lng: "de", title: "German" },
        { code: "ae", lng: "ar", title: "Arabic" },
        { code: "pl", lng: "pl", title: "Polish" },
        { code: "pt", lng: "pt", title: "Portuguese" },
        { code: "in", lng: "hi", title: "English" },
        { code: "cz", lng: "cs", title: "Czech" },
        { code: "vn", lng: "vi", title: "Vietnam" },
        { code: "tr", lng: "tr", title: "Turkish" },
        { code: "ua", lng: "uk", title: "Ukraine" },
        { code: "fi", lng: "fi", title: "Finland" },
        { code: "id", lng: "id", title: "Indonesian" },
        { code: "jp", lng: "ja", title: "Japanese" },
        { code: "cn", lng: "zh", title: "Chinese" },
        { code: "kr", lng: "ko", title: "Korean" },
      ],
      flag_lng: i18n.language,
    };
  }

  componentDidMount() {
    if(!storage.getKey("is_lng_changed")) {
      fetch(
        "https://api.geoapify.com/v1/ipinfo?&apiKey=2e352859d8cc4017acb33698fbede100"
      )
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        this.setState({ flag_lng: res.country.languages[0].iso_code });
      })
      .catch((err) => console.log(err));
    }
  }

  render() {
    return (
      <>
        <Dropdown
          onSelect={(eventKey) => {
            i18n.changeLanguage(eventKey);
            storage.setKey("lng", eventKey);
            storage.setKey("is_lng_changed", true);
            this.setState({ flag_lng: eventKey });
            if (eventKey === "ar" || eventKey === "fa") {
              Event.emit("direction_change", true);
            } else {
              Event.emit("direction_change", false);
            }
          }}
          drop="up"
        >
          <Dropdown.Toggle
            variant="rimary"
            id="translation-flags"
            className="text-left"
          >
            <ReactCountryFlag
              countryCode={
                __.get(
                  __.find(this.state.countries, { lng: this.state.flag_lng }),
                  "code"
                ) || "us"
              }
              style={{ width: "20px" }}
              svg
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="flag-dropdown-menu">
            {this.state.countries.map(({ code, lng, title }) => (
              <Dropdown.Item key={code} eventKey={lng}>
                <ReactCountryFlag
                  countryCode={code}
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    position: "relative",
                    top: "-2px",
                  }}
                  svg
                />{" "}
                {title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default Translation;
