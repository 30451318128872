import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isMobile, wait, Event, encode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

import "../../../Static/css/hilo.css";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }
    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;

    const help = (
      <>
        <p className={"font-light text-white font-15"}>
          Hi-lo, also referred to as high low boasts incredibly simple and
          straightforward rules. The aim of the game is to guess whether the
          next card to be dealt will be higher or lower. Guess right and you
          win, guess wrong and you lose, it’s that simple.
          <b>Hilo Tips & Tricks </b>
          Place your bet on the table
          <br />
          A card is dealt <br />
          Choose whether the next card will be higher or lower
          <br />
          The next card is dealt
          <br />
          If you guessed incorrectly, your stake is lost, and the game round
          ends
          <br />
          You must place another bet to play again
          <br />
          If you guessed correctly, you win a payout
          <br />
          The result card for the round becomes the base card for the next round
          <br />
          Play again or collect your balance
        </p>
        <h4 className="text-white text-yellow">How to Play ?</h4>
        <p className={"font-light text-white font-15"}>
          The game is not timed. Click "Bet" to start betting and get the first
          card.
          <br />
          At this point you can guess the next card is "higher or same" or
          "lower or same" or “skip”.
          <br />
          If you guess right, you will get the corresponding payout.
          <br />
          You can choose to claim the win and stop this round or continue
          guessing the next card.
          <br />
          The more cards you guess, the bigger payout you get.
          <br />
          There is no max payout, only bet and profit limits.
          <br />
          Ace is the lowest card, king is the highest card. The value order is
          K,Q,J,10,9,8,7,6,5,4,3,2,A.
        </p>
      </>
    );

    return (
      <>
        <Helmet>
          <title>Hilo - Crypto Casino Games</title>
          <meta
            name="description"
            content="Crypto Hilo Game, also referred to as high low boasts incredibly simple and straightforward rules.The aim of the game is to guess whether the next card to be dealt will be higher or lower. Guess right and you win, guess wrong and you lose, it’s that simple. "
          />
          <meta
            name="og:title"
            content="Play Online Hilo - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Hilo - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/hilo" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">Crypto Hilo Game Description: {help}</div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            hilo
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"hilo"} />%
                          </span>
                        </p>
                        <BankRoll game={"hilo"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Hilo" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body className={"bg-dice-options p-1"} id="roll-panel">
                    <Row>
                      <Col sm={12} md={10} className={"m-auto"}>
                        <Game engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
