import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import { isMobile, wait, Event, encode } from "../../../Helper";
import { Helmet } from "react-helmet";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Main extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-0",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;
    this.state.canvas = null;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }
    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;

    const help = (
      <>
        <p className={"font-light text-white font-15"}>
          Single Keno is a gambling game played with cards bearing numbers in
          squares, from 1 to 40. You will choose as many numbers as you wish and
          then submit your bet. Random number will be drawn, and prizes are paid
          out by the casino according to how many numbers you selected were
          chosen.
          <br />
          <b>Keno Game Tips & Tricks</b>
          <br />
          The more number combination chosen, the higher probability of a win or
          higher payout. The maximum possible selection and maximum possible
          successful5 hits is set to 10. Betting on both even and odd numbers
          betters your chances of winning.
        </p>
        <h4 className="text-white text-yellow">How to Play Game?</h4>
        <p className="text-white font-15">
          1. More the number combinations selected, higher the potential payout.
          <br />
          2.The more selections hit successfully, the higher will be the payout
          multiplier.
          <br />
          3.Maximum possible selection and maximum possible successful hits is
          10.
        </p>
      </>
    );

    return (
      <>
        <Helmet>
          <title>Single Keno - Crypto Casino Games</title>
          <meta
            name="description"
            content="Crypto Single Keno is a gambling game played with cards bearing numbers in squares, from 1 to 40. You will choose as many numbers as you wish and then submit your bet. Random number will be drawn"
          />
          <meta
            name="og:title"
            content="Play Online Single Keno - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Single Keno - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/singlekeno" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"flex-column animated fadeIn " + ovh}>
              <div className="d-none">{help}</div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            single keno
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"singlekeno"} />%
                          </span>
                        </p>
                        <BankRoll game={"singlekeno"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames
                          gid={gid}
                          help={help}
                          image="Single Keno"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={"py-0 " + padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"mx-auto mt-2"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col sm={12} className={'mt-0 mx-auto p-0 ' + col}> */}
              <Col className={"mx-auto"}>
                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Main;
